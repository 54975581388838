<template>
  <base-card :loading="loading" class="pb-2 rounded-lg">
    <v-tabs v-model="tab" show-arrows class="rounded-t-lg">
      <v-tab
        v-for="tab in !party ? [] : tabsFiltered"
        class="rounded-t-lg"
        :key="tab.component"
      >
        <v-icon left small>{{ tab.icon }}</v-icon>
        {{ tab.name }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" :touchless="tabsFiltered[tab]?.touchless">
      <v-tab-item v-for="item in !party ? [] : tabsFiltered" :key="item.name">
        <component
          :is="item.component"
          :party="party"
          :loading="loading"
          @refresh="getParty"
          class="px-4 pb-4"
        />
      </v-tab-item>
    </v-tabs-items>
  </base-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PartyDetails from "@/components/admin/party/PartyDetails";
import PartyPolicies from "@/components/admin/party/PartyPolicies";
import PartyAttractions from "@/components/admin/party/PartyAttractions.vue";
import PartyRating from "@/components/admin/party/PartyRating.vue";
import PartyGallery from "@/components/admin/party/PartyGallery.vue";
import PartyPeriod from "@/components/admin/party/PartyPeriod.vue";
import PartyMap from "@/components/admin/party/PartyMap.vue";
import PartyWhatsappGroup from "@/components/admin/party/PartyWhatsappGroup.vue";
import PartyTableMap from "@/components/admin/party/PartyTableMap.vue";

export default {
  metaInfo() {
    return { title: `ℹ️ ${this.party?.name || "Carregando..."}` };
  },
  data: () => ({
    loading: true,
    tab: null,
    tabs: [
      {
        id: "details",
        name: "Detalhes",
        component: "PartyDetails",
        permission: 2,
        icon: "mdi-information",
      },
      {
        id: "policies",
        name: "Políticas",
        component: "PartyPolicies",
        permission: 2,
        icon: "mdi-security",
      },
      {
        id: "attractions",
        name: "Atrações",
        component: "PartyAttractions",
        permission: 2,
        icon: "mdi-music",
      },
      {
        id: "gallery",
        name: "Galeria",
        component: "PartyGallery",
        permission: 2,
        icon: "mdi-image",
      },
      {
        id: "period",
        name: "Períodos",
        component: "PartyPeriod",
        permission: 2,
        icon: "mdi-calendar-blank-multiple",
      },
      {
        id: "table-map",
        name: "Mapa de Mesas",
        component: "PartyTableMap",
        permission: 2,
        touchless: true,
        icon: "mdi-table-chair",
      },
      {
        id: "map",
        name: "Mapa 3D",
        component: "PartyMap",
        permission: 2,
        icon: "mdi-map",
      },
      {
        id: "rating",
        name: "Avaliações",
        component: "PartyRating",
        permission: 1048576,
        icon: "mdi-star",
      },
      {
        id: "wa-group",
        name: "Grupos do Whatsapp",
        component: "PartyWhatsappGroup",
        permission: 2097152,
        icon: "mdi-whatsapp",
      },
    ],
  }),
  methods: {
    ...mapActions("organization", { updateParty: "partyById" }),
    async getParty() {
      try {
        this.selectTab();
        this.loading = true;
        await this.updateParty(this.$route.params.partyId);
        this.loading = false;
        this.$root.$emit("setImgBg", this.party?.cover);
      } catch (error) {
        this.$router.push({ name: "admin.parties" });
      }
    },
    selectTab() {
      if (this.$route.hash) {
        let hash = this.$route.hash.replace("#", "");
        let index = this.tabsFiltered.findIndex((tab) => tab.id == hash);
        if (index) this.tab = index;
      }
    },
    vibrate() {
      if (navigator.vibrate) navigator.vibrate(50);
    },
  },
  destroyed() {
    this.$root.$emit("setImgBg", null);
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("organization", { getPartyById: "partyById" }),
    ...mapGetters("auth", ["hasPermission"]),
    party() {
      return this.getPartyById(this.$route.params.partyId);
    },
    tabsFiltered() {
      return this.tabs.filter(
        (tab) => !tab.permission || this.hasPermission(tab.permission)
      );
    },
  },
  watch: {
    tab: {
      handler(val, oldVal) {
        if (val !== null && oldVal !== null) this.vibrate();
        let tab = this.tabsFiltered[val];
        if (tab) window.location.replace("#" + tab.id);
      },
      immediate: true,
    },
    "$route.hash"() {
      this.selectTab();
    },
    "$route.params.partyId"() {
      this.getParty();
    },
    "selectedOrganization.id"() {
      this.$router.push({
        name: "admin.parties",
      });
    },
  },
  components: {
    PartyDetails,
    PartyAttractions,
    PartyRating,
    PartyGallery,
    PartyPeriod,
    PartyPolicies,
    PartyMap,
    PartyTableMap,
    PartyWhatsappGroup,
  },
  mounted() {
    this.getParty();
  },
};
</script>

<style></style>
