<template>
  <div class="mt-2">
    <template v-if="initialLoading">
      <v-progress-linear indeterminate />
      <v-alert type="info" text dense> Por favor, aguarde um momento </v-alert>
    </template>
    <div v-show="!initialLoading">
      <v-row v-show="fileUrl && !fileLoading">
        <v-col cols="12" md="7">
          <div style="position: relative">
            <GLTFViewer
              class="rounded"
              ref="gltf"
              :options="options"
              v-bind:loading.sync="fileLoading"
            ></GLTFViewer>
            <v-overlay absolute :value="fileLoading">
              <v-progress-circular indeterminate color="primary" />
            </v-overlay>
          </div>
          <v-btn text small block @click="changeFile">Trocar modelo</v-btn>
        </v-col>
        <v-col cols="12" md="5">
          <v-btn block color="success" @click="save">Salvar</v-btn>
          
          <v-expansion-panels class="mt-4">
            <!-- Configurações -->
            <v-expansion-panel>
              <v-expansion-panel-header>
                Configurações
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-color-picker
                  label="Cor do fundo"
                  v-model="options.scene.background.color"
                  mode="hexa"
                ></v-color-picker>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <!-- Posições -->
            <v-expansion-panel>
              <v-expansion-panel-header> Posições </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="d-flex">
                  <v-text-field
                    v-model="newPositionName"
                    label="Nome da posição"
                    outlined
                    dense
                  />
                  <v-btn
                    class="mx-2"
                    fab
                    color="primary"
                    small
                    @click="savePosition"
                    :disabled="!newPositionName"
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </div>

                <v-list>
                  <v-list-item-group>
                    <v-list-item
                      v-for="(position, i) in validPositions"
                      :key="`${position.name}-${i}`"
                      @click="goToPosition(position)"
                    >
                      <v-list-item-avatar size="60">
                        <v-img
                          :src="position.image || position.url"
                          class="rounded"
                          :aspect-ratio="1"
                          height="70px"
                        ></v-img>
                      </v-list-item-avatar>
                      <v-list-item-title>{{ position.name }}</v-list-item-title>
                      <v-list-item-action>
                        <v-btn icon @click="removePosition(position.yndex)">
                          <v-icon color="secondary">mdi-delete</v-icon>
                        </v-btn>
                        <v-btn icon @click="setPositionDefault(position.yndex)">
                          <v-icon
                            color="grey"
                            :class="position.default && 'primary--text'"
                          >
                            mdi-star
                          </v-icon>
                        </v-btn>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row v-show="!fileUrl || fileLoading" align="stretch">
        <v-col cols="12" md="6">
          <file-drop
            @change="loadFile"
            :disabled="!!fileLoading"
            accept=".gltf,.zip"
            label="Arraste o arquivo do modelo 3D (.gltf ou .zip) aqui"
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-alert type="info">
            Formatos aceitos:
            <ul>
              <li>.gltf - Arquivos glTF compactados</li>
              <li>
                .zip - Arquivo .zip contendo o arquivo .gltf e os arquivos de
                textura
              </li>
            </ul>
          </v-alert>
        </v-col>

        <v-col cols="12" v-if="fileLoading" class="pt-0">
          <v-progress-linear
            :indeterminate="fileLoading === true"
            :value="fileLoading === true ? 0 : fileLoading"
          />
          <v-alert type="info" dense text>
            Carregando modelo, esse processo pode demorar alguns minutos. <br />
            Por favor, mantenha a página aberta.
          </v-alert>
        </v-col>
      </v-row>
    </div>
    <map-save-modal :party="party" @map="applyMap" />
  </div>
</template>

<script>
import PARTY from "@/services/admin/party";

import FileDrop from "../../global/FileDrop.vue";
import GLTFViewer from "../../global/GLTFViewer.vue";
import MapSaveModal from "./modal/MapSaveModal.vue";
export default {
  components: { GLTFViewer, FileDrop, MapSaveModal },
  mounted() {
    this.getData();
  },
  data: () => ({
    initialLoading: true,
    loading: 0,
    fileLoading: false,

    positions: [],
    fileUrl: null,
    file: null,

    newPositionName: null,

    options: {
      scene: {
        background: {
          type: "color",
          color: "#5e5ce6",
        },
      },
    },
  }),
  watch: {
    fileUrl() {
      if (!this.fileUrl) return;
      var type = "gltf";
      if (this.file) type = this.file?.type.includes("zip") ? "zip" : "gltf";
      else type = this.fileUrl.includes("zip") ? "zip" : "gltf";

      this.$refs.gltf.load({
        type: type,
        url: this.fileUrl,
      });
    },
  },
  methods: {
    save() {
      this.$emit("save", {
        file: this.file,
        positions: this.positions,
        options: this.options,
      });
    },
    applyMap(map) {
      this.file = null;
      this.fileUrl = this.formatGCloudUrl(map?.url);
      this.positions =
        map?.Positions.map((p) => ({
          ...p,
          ...p.object,
          object: undefined,
          deleted: false,
        })) || [];
      this.options = Object.assign(this.options, map?.options || {});
    },
    formatGCloudUrl(url) {
      if (!url) return null;
      const split = url.split("/");

      const bucket = split[3];
      split.splice(3, 1);

      const newUrl = split.join("/");
      return newUrl.replace("https://", `https://${bucket}.`);
    },
    async getData() {
      this.initialLoading = true;
      try {
        const { map } = await PARTY.map.get(
          this.party.organizationId,
          this.party.id
        );
        this.applyMap(map);

        this.initialLoading = false;
      } catch (e) {
        console.log(e);
        this.initialLoading = false;
      }
    },

    changeFile() {
      this.file = null;
      this.fileUrl = null;
      this.positions = [];
      this.$refs.gltf.initScene();
      this.$refs.gltf.setScene(this.options);
    },
    loadFile(file) {
      this.file = file;
      this.fileUrl = URL.createObjectURL(file);
    },
    setPositionDefault(index) {
      this.positions.forEach((position) => (position.default = false));
      this.positions[index].default = true;
    },
    savePosition() {
      this.positions.push({
        name: this.newPositionName,
        default: !this.positions.length,
        image: this.$refs.gltf.canvas.toDataURL(),
        position: {
          x: this.$refs.gltf.camera.position.x,
          y: this.$refs.gltf.camera.position.y,
          z: this.$refs.gltf.camera.position.z,
        },
        rotation: {
          x: this.$refs.gltf.camera.rotation.x,
          y: this.$refs.gltf.camera.rotation.y,
          z: this.$refs.gltf.camera.rotation.z,
        },
        target: {
          x: this.$refs.gltf.controls.target.x,
          y: this.$refs.gltf.controls.target.y,
          z: this.$refs.gltf.controls.target.z,
        },
        deleted: false,
      });
      this.newPositionName = null;
    },
    removePosition(index) {
      const { default: isDefault, id } = this.positions[index];
      if (id) this.positions[index].deleted = true;
      else this.positions.splice(index, 1);

      const yndex = this.positions.findIndex((position) => !position.deleted);
      if (isDefault && yndex > -1) this.positions[yndex].default = true;
    },
    goToPosition(position) {
      this.$refs.gltf.camera.position.set(
        position.position.x,
        position.position.y,
        position.position.z
      );
      this.$refs.gltf.camera.rotation.set(
        position.rotation.x,
        position.rotation.y,
        position.rotation.z
      );
      this.$refs.gltf.controls.target.set(
        position.target.x,
        position.target.y,
        position.target.z
      );
    },
  },
  computed: {
    validPositions() {
      return this.positions
        .map((v, i) => ({ ...v, yndex: i }))
        .filter((position) => !position.deleted);
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
