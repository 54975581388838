<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between">
      <h6>Vendas por Lote</h6>
      <v-btn-toggle mandatory v-model="display">
        <v-btn small> R$ </v-btn>
        <v-btn small> Qtde </v-btn>
        <v-btn small> Tickets </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <apexCharts
        type="bar"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      />
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    ApexCharts,
  },
  data: () => ({
    error: null,
    display: 0,
  }),
  methods: {
    formatNumber(value) {
      return this.display === 0
        ? value
          ? this.$options.filters.currency(value, true)
          : ""
        : value.toFixed(0);
    },
  },
  computed: {
    ticketBlocks() {
      return this.data.tickets.reduce((acc, t) => {
        const block = `${t.TicketBlock.TicketGroup.name} - ${t.TicketBlock.name}`;
        (acc[block] = acc[block] || []).push(t);
        return acc;
      }, {});
    },
    chartSeries() {
      const paymentsSummed = [];
      var approved = Object.values(this.ticketBlocks).map((tickets) => {
        return tickets.reduce((acc, t) => {
          if (t.Payment.status !== "succeeded") return acc;
          if (this.display < 2 && paymentsSummed.includes(t.Payment.id))
            return acc;
          paymentsSummed.push(t.Payment.id);

          return acc + (this.display === 0 ? t.Payment.amount : 1);
        }, 0);
      });
      var pending = Object.values(this.ticketBlocks).map((tickets) => {
        return tickets.reduce((acc, t) => {
          if (["canceled", "refunded", "succeeded"].includes(t.Payment.status))
            return acc;
          if (this.display < 2 && paymentsSummed.includes(t.Payment.id))
            return acc;
          paymentsSummed.push(t.Payment.id);
          return acc + (this.display === 0 ? t.Payment.amount : 1);
        }, 0);
      });
      var refunded = Object.values(this.ticketBlocks).map((tickets) => {
        return tickets.reduce((acc, t) => {
          if (t.Payment.status !== "refunded") return acc;
          if (this.display < 2 && paymentsSummed.includes(t.Payment.id))
            return acc;
          paymentsSummed.push(t.Payment.id);
          return acc + (this.display === 0 ? t.Payment.amount : 1);
        }, 0);
      });

      return [
        {
          name: "Aprovadas",
          data: approved,
        },
        {
          name: "Pendentes",
          data: pending,
        },
        {
          name: "Reembolsadas",
          data: refunded,
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: "bar",
          // height: 350,
          stacked: true,
          toolbar: { show: false },
        },
        plotOptions: { bar: { horizontal: true } },
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 1,
        },
        colors: ["#5e5ce6", "#ff5722", "#607188"],
        dataLabels: { formatter: this.formatNumber },
        xaxis: {
          categories: Object.keys(this.ticketBlocks),
          title: { text: this.display === 0 ? "R$" : "Qtde" },
          axisBorder: { show: false },
          labels: { formatter: () => "" },
          tickAmount: 100,
          axisTicks: { show: false },
        },
        yaxis: {
          title: { text: "Lote" },
          axisTicks: { show: false },
        },
        grid: {
          borderColor: "transparent",
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        legend: { position: "top" },
        tooltip: {
          x: { show: true },
          y: { formatter: this.formatNumber },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>
.apexcharts-yaxis-lote {
  white-space: pre-line;
}
</style>
