<template>
  <v-card outlined :loading="loading">
    <v-card-title class="d-flex justify-space-between pb-0">
      <h6>Forma de Pagamento</h6>
      <v-btn-toggle mandatory v-model="display" class="ml-2">
        <v-btn small> R$ </v-btn>
        <v-btn small> Qtde </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <v-select
        dense
        outlined
        v-model="paymentStatus"
        :items="paymentStatusSelectList"
        label="Status do Pagamento"
      />
      <v-alert type="warning"  dense class="mb-0" v-if="!chartSeries.length" outlined>
        Nenhum pagamento <b>{{ statusTranslate[paymentStatus].toLowerCase() }}</b>.
      </v-alert>

      <apexCharts
        v-else
        type="donut"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      />
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import paymentUtils from "@/utils/payment";
import ApexCharts from 'vue-apexcharts'

export default {
  components: {
    ApexCharts
  },
  data: () => ({
    error: null,
    display: 0,
    paymentStatus: "succeeded",

    statusTranslate: {
      pending: "Pendente",
      succeeded: "Pago",
      refunded: "Reembolsado",
    },
    status: {
      pending: "pending",
      requires_payment_method: "pending",
      requires_confirmation: "pending",
      requires_action: "pending",
      processing: "pending",
      requires_capture: "succeeded",
      canceled: "canceled",
      refunded: "refunded",
      succeeded: "succeeded",
      rejected: "rejected",
      disputed: "refunded",
    },
  }),
  computed: {
    paymentStatusSelectList() {
      return Object.keys(this.statusTranslate).map((key) => ({
        text: this.statusTranslate[key],
        value: key,
      }));
    },
    paymentTypes() {
      const paymentStatusList = Object.entries(this.status)
        .filter(([key, value]) => value == this.paymentStatus)
        .map(([key, value]) => key);

      return this.data.payments
        .filter((payment) => paymentStatusList.includes(payment.status))
        .reduce((acc, t) => {
          const paymentType = t.paymentType;
          if (!acc[paymentType]) {
            acc[paymentType] = 0;
          }
          acc[paymentType] += this.display ? 1 : t.amount;

          return acc;
        }, {});
    },
    chartSeries() {
      if (!this.paymentTypes) return [];
      return Object.values(this.paymentTypes);
    },
    chartOptions() {
      return {
        chart: {
          type: "donut",
          height: 350,
        },
        labels: Object.keys(this.paymentTypes).map(
          (key) => paymentUtils.paymentType[key]?.text || key
        ),
        colors: Object.keys(this.paymentTypes).map(
          (key) => paymentUtils.paymentType[key]?.color || key
        ),
        dataLabels: {
          enabled: false,
        },
        yaxis: {
          labels: {
            formatter: (value) =>
              this.display
                ? `${value} pagamento${value != 1 ? "s" : ""}`
                : value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }),
          },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
