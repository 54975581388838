<template>
  <div>
    <h6>Ingressos</h6>
    <v-data-table
      dense
      single-expand
      show-expand
      hide-default-footer
      :items-per-page="ticketsStatus.length"
      :headers="headers"
      :items="ticketsStatus"
    >
      <template #item.ticketBlock="{ item }">
        {{ item.TicketBlock.TicketGroup.name }} -
        {{ item.TicketBlock.name }}
      </template>
      <template #item.amount="{ item }">
        {{ item.Payment.amount | currency }}
      </template>
      <template #item.seller="{ item }">
        {{ item.Seller?.name || item.SaleLink?.name }}
      </template>
      <template #item.status="{ item }">
        <v-chip label outlined x-small :color="item.status.color">
          {{ item.status.text }}
        </v-chip>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <ticket-row :ticket="item" />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PAYMENT from "@/utils/payment";
import TicketRow from "./TicketRow.vue";

export default {
  components: { TicketRow },
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    ticketStatus: PAYMENT.ticketStatus,
    headers: [
      { text: "Lote", value: "ticketBlock", sortable: false },
      { text: "Valor", value: "amount", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "Promoter", value: "seller", sortable: false },
      { text: "", value: "data-table-expand" },
    ],
  }),
  computed: {
    ticketsStatus() {
      return this.tickets.map((ticket) => ({
        ...ticket,
        status: this.calculateStatus(ticket),
      }));
    },
  },
  methods: {
    refresh(close = false) {
      this.$emit("refresh", close);
    },

    calculateStatus(ticket) {
      return (
        this.ticketStatus[ticket.Payment.status] || {
          color: "secondary",
          text: "Não pago",
        }
      );
    },
  },
};
</script>

<style></style>
