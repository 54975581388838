<template>
  <div class="mt-3">
    <v-alert v-if="error" type="error">
      {{ error }}
    </v-alert>
    <table-map-editor
      v-if="tableMap"
      :table-map="tableMap"
      :party="party"
      :loading="loading"
      @save="save"
    />
  </div>
</template>

<script>
import TableMapEditor from "./tableMap/TableMapEditor.vue";
import TABLE from "@/services/admin/party/table";

export default {
  components: { TableMapEditor },
  data: () => ({
    loading: false,
    error: false,
    tableMap: null,
  }),
  methods: {
    async save(mapData) {
      try {
        console.log(mapData);
        this.loading = true;

        const hasFileToUpload = mapData.backgroundUrl instanceof File;
        const data = {
          backgroundUrl: hasFileToUpload ? null : mapData.backgroundUrl,
          Groups: mapData.Groups.map((group) => ({
            id: Number.isInteger(group.id) ? null : group.id,
            name: group.name,
            color: group.color,
            capacity: group.capacity,
            options: group.options,
            Tables: group.Tables.map((table) => ({
              id: Number.isInteger(table.id) ? null : table.id,
              name: table.name,
              position: table.position,
              sellOnline: table.sellOnline,
              // options: table.options,
            })),
          })),
        };

        await TABLE.upsert(this.party.organizationId, this.party.id, data);

        if (hasFileToUpload) {
          await TABLE.upload(
            this.party.organizationId,
            this.party.id,
            mapData.backgroundUrl
          );
        }

        this.getTableMap();
      } catch (error) {
        this.error = error.message || "Erro ao salvar avaliação";
      } finally {
        this.loading = false;
      }
    },
    async getTableMap() {
      try {
        this.loading = true;
        const { tableMap } = await TABLE.get(
          this.party.organizationId,
          this.party.id
        );
        this.tableMap = tableMap || { backgroundUrl: null, Groups: [] };
      } catch (error) {
        this.error = error.message || "Erro ao carregar avaliações";
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {},

  mounted() {
    this.getTableMap();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
