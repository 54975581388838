<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-end">
      <h6>Evolução de vendas</h6>
      <v-spacer />
      <v-btn-toggle mandatory v-model="display" class="ml-2">
        <v-btn small> R$ </v-btn>
        <v-btn small> Qtde </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <apexCharts
        type="area"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      />

      <!-- {{ xaxis }} -->
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    ApexCharts,
  },
  data: () => ({
    error: null,
    display: 0,
  }),
  methods: {
    formatValue(value) {
      return this.display === 0
        ? value
          ? this.$options.filters.currency(value, true)
          : ""
        : value.toFixed(0) + " ingresso" + (value != 1 ? "s" : "");
    },
  },
  computed: {
    xaxis() {
      // sort sales
      const paymentsSorted = this.data.payments.sort((a, b) => {
        return moment(a.payedAt || a.createdAt).diff(
          moment(b.payedAt || b.createdAt)
        );
      });

      // get first and last day of sales
      
      const firstDay = moment(paymentsSorted[0].createdAt).startOf("day");
      const lastDay = moment(
        paymentsSorted[paymentsSorted.length - 1].payedAt ||
          paymentsSorted[paymentsSorted.length - 1].createdAt
      ).startOf("day");

      //  populate array with days between first and last day
      const days = [];
      let day = firstDay;
      while (day <= lastDay) {
        days.push(day.format("DD/MM/YY"));
        day = day.clone().add(1, "d");
      }

      return days;
    },
    chartSeries() {
      const salesByDay = this.xaxis.reduce(
        (acc, day) => ({ ...acc, [day]: 0 }),
        {}
      );
      const refundsByDay = { ...salesByDay };

      this.data.payments.forEach((p) => {
        if (!["succeeded", "refunded"].includes(p.status)) return;

        const value = this.display === 0 ? p.amount : p.Ticket.length;

        if (p.status === "refunded") {
          let pDay = moment(p.payedAt || p.createdAt).format("DD/MM/YY");
          let rDay = moment(p.refundedAt || p.createdAt).format("DD/MM/YY");

          salesByDay[pDay] += value;
          refundsByDay[rDay] += value;
          salesByDay[rDay] -= value;
        } else {
          let day = moment(p.refundedAt || p.createdAt).format("DD/MM/YY");
          salesByDay[day] += value;
        }
      });

      var salesValue = Object.values(salesByDay),
        refundsValue = Object.values(refundsByDay);


      for (let i = 0; i < salesValue.length; i++) {
        if (i === 0) continue;
        else {
          salesValue[i] = salesValue[i] + salesValue[i - 1];
          refundsValue[i] = refundsValue[i] + refundsValue[i - 1];
        }
      }

      return [
        {
          name: "Vendas",
          data: salesValue,
        },
        {
          name: "Reembolsos",
          data: refundsValue,
        },
      ];
    },
    chartOptions() {
      return {
        chart: {
          type: "area",
          height: 350,
          toolbar: { show: false },
        },
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 1,
        },
        colors: ["#5e5ce6", "#f1556c"],
        stroke: { width: 7, curve: "smooth" },
        dataLabels: { formatter: this.formatValue },
        xaxis: {
          categories: this.xaxis,
          title: { text: "Dia" },
          axisBorder: { show: false },
        },
        yaxis: {
          title: { text: this.display === 0 ? "R$" : "Qtde" },
          labels: { formatter: this.formatValue },
        },
        markers: {
          size: 5,
          hover: { size: 7 },
        },
        grid: {
          borderColor: "transparent",
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        tooltip: {
          x: { show: true },
          y: {
            formatter: (seriesName) =>
              this.display === 0
                ? seriesName.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : seriesName.toFixed(0) +
                  " ingresso" +
                  (seriesName != 1 ? "s" : ""),
          },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
