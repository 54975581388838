<template>
  <v-card elevation="0">
    <template v-if="!error && !!lastUpdate">
      <div class="mt-2 d-flex align-center">
        <v-btn
          @click="goToFinancial"
          text
          x-small
          color="primary"
          v-if="hasPermission(2048)"
        >
          Ver financeiro completo
          <v-icon x-small right>mdi-open-in-new</v-icon>
        </v-btn>

        <v-spacer />

        <v-btn
          @click="getReport"
          icon
          small
          :loading="loading"
          :disabled="blockRefresh"
          :class="loading ? 'mr-2' : ''"
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        <span class="text-caption" v-if="lastUpdate">
          Última atualização: {{ lastUpdate }}
        </span>
      </div>

      <div class="pa-2">
        <highlights :data="{ party, ...reportData }" :loading="loading" />
      </div>

      <cols-organizer md="2" xl="3" :items="computedReports">
        <template v-slot="{ item }">
          <component
            :is="item"
            :loading="loading"
            :data="{ party, ...reportData }"
          />
        </template>
      </cols-organizer>
    </template>
    <v-alert v-else-if="error" type="error" class="mb-0">
      <v-row align="center">
        <v-col class="grow py-0">
          {{ error }}
        </v-col>
        <v-col class="shrink py-0">
          <v-btn small @click="getReport" :disabled="blockRefresh">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <template v-else>
      <v-progress-linear indeterminate color="primary" class="mt-2" />
      <v-alert type="info" text class="mt-0">
        Carregando relatório, por favor aguarde...
        <p v-if="oldEvent" class="mb-0">
          Eventos antigos podem demorar mais para carregar
        </p>
      </v-alert>
    </template>
  </v-card>
</template>

<script>
import moment from "moment";
import ORGANIZATION from "@/services/admin/organization";
const PARTYREPORTS = ORGANIZATION.party.reports;

import SalesByDay from "../../../views/admin/party/reports/SalesByDay.vue";
import SalesEvolution from "../../../views/admin/party/reports/SalesEvolution.vue";
import SalesByTicketBlock from "../../../views/admin/party/reports/SalesByTicketBlock.vue";
import SalesBySeller from "../../../views/admin/party/reports/SalesBySeller.vue";
import SalesByPaymentType from "../../../views/admin/party/reports/SalesByPaymentType.vue";
import RefundedSales from "../../../views/admin/party/reports/RefundedSales.vue";
import CourtesySales from "../../../views/admin/party/reports/CourtesySales.vue";
import TableReport from "../../../views/admin/party/reports/TableReport.vue";
import SalesInCalendar from "../../../views/admin/party/reports/SalesInCalendar.vue";
import SalesForMemberships from "../../../views/admin/party/reports/SalesForMemberships.vue";
import ColsOrganizer from "../../global/ColsOrganizer.vue";
import Highlights from "../../../views/admin/party/reports/Highlights.vue";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    loading: true,
    blockRefresh: false,
    error: false,
    reports: {
      sales_by_day: true,
      sales_evolution: true,
      sales_in_calendar: true,
      sales_by_ticket_block: true,
      sales_by_payment_type: true,
      sales_for_memberships: function ({ tickets }) {
        return (
          this.hasPermission([16, 4194304, 8388608]) &&
          tickets.some((t) => !!t.Payment.MembershipFiliation)
        );
      },
      table_report: function ({ tickets }) {
        return false;
        return tickets.some((t) => !!t.tableId);
      },
      refunded_sales: true,
      sales_by_seller: true,
      courtesy_sales: true,
    },
    reportData: {},
    lastUpdate: null,
    interval: null,
  }),

  methods: {
    goToFinancial() {
      this.$router.push({
        name: "admin.transactions.party",
        params: { partyId: this.party.id },
      });
    },
    async getReport() {
      try {
        this.error = null;
        this.loading = true;
        this.blockRefresh = true;

        const { payments } = await PARTYREPORTS.payments(
          this.party.organizationId,
          this.party.id
        );
        this.reportData.payments = payments;
        const { tickets } = await PARTYREPORTS.tickets(
          this.party.organizationId,
          this.party.id
        );
        this.reportData.tickets = tickets;
        this.lastUpdate = moment().format("HH:mm:ss");
        this.enableRefresh(10000);
      } catch (e) {
        this.error = e.message || "Erro ao carregar relatório";
        this.enableRefresh(5000);
      } finally {
        this.loading = false;
      }
    },
    enableRefresh(time) {
      setTimeout(() => {
        this.blockRefresh = false;
      }, time);
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    oldEvent() {
      return moment(this.party.date).isBefore(moment().subtract(3, "months"));
    },
    computedReports() {
      if (!this.reportData.payments || !this.reportData.tickets) return [];
      return Object.keys(this.reports).filter((r) => {
        if (typeof this.reports[r] === "function")
          return this.reports[r].bind(this)(this.reportData);
        return this.reports[r];
      });
    },
  },
  mounted() {
    this.getReport();
    this.interval = setInterval(() => {
      this.getReport();
    }, 2 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  components: {
    sales_by_day: SalesByDay,
    sales_by_ticket_block: SalesByTicketBlock,
    sales_by_seller: SalesBySeller,
    sales_by_payment_type: SalesByPaymentType,
    sales_in_calendar: SalesInCalendar,
    refunded_sales: RefundedSales,
    courtesy_sales: CourtesySales,
    sales_evolution: SalesEvolution,
    table_report: TableReport,
    sales_for_memberships: SalesForMemberships,
    ColsOrganizer,
    Highlights,
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
