<template>
  <div>
    <div class="d-flex justify-space-between align-center mt-2">
      <h6 class="mb-0">Vendedores</h6>
      <!-- <v-btn
        color="primary"
        @click="addSeller"
        :small="$vuetify.breakpoint.mdAndDown"
      >
        <v-icon left :small="$vuetify.breakpoint.mdAndDown"> mdi-plus </v-icon>
        Vendedor
      </v-btn> -->
    </div>
    <v-alert v-if="error" type="error" class="mt-2">
      {{ error }}
    </v-alert>

    <v-data-table v-else :headers="headers" :items="sellers" :loading="loading">
      <template v-slot:item.name="{ item }">
        <div class="d-flex gap-2 align-center">
          <baseAvatar
            v-if="!item.photo && item.name"
            :size="35"
            :seed="item.id"
          />
          <v-avatar v-else size="35">
            <img v-if="item.name" :src="item.photo" />
            <v-icon v-else large>mdi-account-question</v-icon>
          </v-avatar>
          <div>
            <p class="mb-0" :class="{ textItalic: !item.name }">
              {{ item.name || "Conta não criada" }}
            </p>
            <small>{{ item.phone }}</small>
          </div>
        </div>
      </template>
      <template v-slot:item.ticketBlocksLength="{ item }">
        <v-tooltip top v-if="item.permissions.owner">
          <template v-slot:activator="{ on, attrs }">
            <v-chip label small v-bind="attrs" v-on="on">
              <v-icon left x-small>mdi-crown</v-icon>
              Todos
            </v-chip>
          </template>
          Acesso a todos os lotes
        </v-tooltip>
        <v-tooltip top v-else-if="!item.TicketBlockSeller.length">
          <template v-slot:activator="{ on, attrs }">
            <v-chip label small v-bind="attrs" v-on="on">
              <v-icon left x-small>mdi-lock</v-icon>
              Nenhum
            </v-chip>
          </template>
          Nenhum lote para venda
        </v-tooltip>

        <v-tooltip top v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-chip label small class="" v-bind="attrs" v-on="on">
              {{ item.TicketBlockSeller.length }} lote{{
                item.TicketBlockSeller.length > 1 ? "s" : ""
              }}
            </v-chip>
          </template>
          <div class="d-flex flex-column">
            <p
              v-for="tb in item.TicketBlockSeller"
              :key="tb.id"
              class="mb-0 d-flex align-center gap-2"
            >
              <b class="font-weight-bold">{{ tb.TicketGroup.name }}</b> •
              {{ tb.name }}
            </p>
          </div>
        </v-tooltip>
      </template>
      <template v-slot:item.permissions="{ item }">
        <div class="d-flex flex-column align-start flex-wrap gap-1">
          <v-chip label x-small v-if="item.permissions.owner">
            <v-icon left x-small>mdi-crown</v-icon>
            Administrador
          </v-chip>
          <v-chip x-small v-else-if="!item.TicketBlockSeller.length">
            <v-icon left x-small>mdi-lock</v-icon>
            Nenhum lote
          </v-chip>
          <template v-else>
            <v-chip x-small v-if="item.permissions.online" color="success">
              <v-icon left x-small> mdi-web </v-icon>
              Venda Online
            </v-chip>
            <v-chip x-small v-if="item.permissions.offline" color="info">
              <v-icon left x-small> mdi-account-cash </v-icon>
              Venda Em Dinheiro
            </v-chip>
          </template>
        </div>
      </template>
      <template v-slot:item.SellerProfile="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-chip v-if="item.SellerProfile" label small color="success">
                <v-icon left x-small>mdi-account-box</v-icon>
                Exibir
              </v-chip>
              <v-chip v-else label small color="info">
                <v-icon left x-small>mdi-account-lock</v-icon>
                Não exibir
              </v-chip>
            </div>
          </template>

          <b>{{ item.SellerProfile ? "Exibir" : "Não exibir" }}</b> perfil do vendedor
          na página do evento
        </v-tooltip>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn @click="editSellerProfile(item)" small icon>
          <v-icon small> mdi-account-box </v-icon>
        </v-btn>
        <v-btn @click="editSeller(item)" small icon>
          <v-icon small> mdi-pencil </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <SellerModal :party="party" @success="getSellers" />
    <SellerProfileModal :party="party" @success="getSellers" />
  </div>
</template>

<script>
import SELLER from "../../../services/admin/party/seller";
import { mapGetters } from "vuex";
import SellerModal from "./modal/SellerModal.vue";
import SellerProfileModal from "./modal/SellerProfileModal.vue";
export default {
  data: () => ({
    loading: false,
    error: null,
    sellers: [],
    ticketBlocks: [],
    headers: [
      {
        text: "Vendedor",
        value: "name",
      },
      {
        text: "Lotes",
        value: "ticketBlocksLength",
        align: "center",
      },
      {
        text: "Permissões",
        value: "permissions",
        sortable: false,
      },
      {
        text: "Perfil",
        value: "SellerProfile",
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ],
  }),
  methods: {
    addSeller() {
      this.$emit("seller-modal");
    },
    editSeller(seller) {
      this.$emit("seller-modal", seller);
    },
    editSellerProfile(seller) {
      this.$root.$emit("seller-profile-modal", seller);
    },
    async getSellers() {
      try {
        this.loading = true;
        const { sellers, ticketBlocks } = await SELLER.getAll(
          this.selectedOrganization.id,
          this.party.id
        );
        this.sellers = sellers.map((s) => ({
          ...s,
          ticketBlocksLength: s.TicketBlockSeller.length,
        }));
        this.ticketBlocks = ticketBlocks;
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.getSellers();
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  components: { SellerModal, SellerProfileModal },
};
</script>

<style></style>
