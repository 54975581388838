<template>
  <v-dialog v-model="dialog" width="500" persistent scrollable eager>
    <v-card>
      <v-card-title class="text-h6">
        {{ attraction.id ? "Editar atração" : "Adicionar atração" }}
      </v-card-title>
      <v-card-text class="pt-1">
        <v-card outlined class="pt-2 pr-4">
          <label class="ml-6 text-overline">Pré visualização</label>
          <v-timeline dense align-bottom>
            <attraction-row :attraction="computedAttraction" />
          </v-timeline>
        </v-card>
        <v-row align="center" justify="center" no-gutters class="mt-2 mx-0">
          <v-col>
            <v-btn @click="choiceImage" block color="primary" depressed :disabled="loading">
              <v-icon>mdi-upload</v-icon>
              <span>
                {{ attraction.image ? "Alterar" : "Carregar" }} Imagem
              </span>
            </v-btn>
          </v-col>
          <v-col v-if="attraction.image || image" cols="auto" class="ml-2">
            <v-btn @click="removeImage" block text :disabled="loading">
              <v-icon small>mdi-delete</v-icon>
              <span>Remover</span>
            </v-btn>
          </v-col>
        </v-row>
        <input
          type="file"
          accept="image/*"
          ref="image"
          v-show="false"
          @change="selectImage"
        />

        <!-- Category -->
        <v-autocomplete
          v-model="attraction.category"
          :items="Object.keys(categories)"
          :item-text="(key) => categories[key].text"
          :item-value="(key) => key"
          outlined
          label="Categoria"
          dense
          :filter="filterCategory"
          class="mt-4"
          :rules="[(v) => !!v || 'Categoria é obrigatória']"
          @change="selectCategory"
          hide-details="auto"
        >
          <template v-slot:selection="{ item }">
            <v-chip :color="attraction.color" class="white--text" small>
              <v-icon left x-small>{{ categories[item].icon }}</v-icon>
              {{ categories[item].text }}
            </v-chip>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-content>
              <v-list-item-title>
                <v-icon left>{{ categories[item].icon }}</v-icon>
                {{ categories[item].text }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>

        <!-- Color -->
        <v-card outlined class="pa-1 pb-0 rounded-lg mt-4">
          <label class="ml-3 pt-5">Cor</label>
          <vue-perfect-scrollbar
            :settings="{
              suppressScrollY: false,
              wheelPropagation: false,
            }"
            class="w-full"
          >
            <div class="mb-3 px-2">
              <div class="d-flex gap-2">
                <div v-for="color in colors" :key="color" class="text-center">
                  <v-card
                    outlined
                    class="pa-0 rounded-lg"
                    :color="color"
                    small
                    icon
                    @click="selectColor(color)"
                  >
                    <v-avatar size="40" :color="color">
                      <v-icon v-if="attraction.color == color" color="white">
                        mdi-check
                      </v-icon>
                    </v-avatar>
                  </v-card>
                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </v-card>

        <v-form v-model="valid" ref="form">
          <v-row>
            <v-col cols="12" class="pb-0">
              <v-text-field
                outlined
                v-model="attraction.name"
                :rules="[(v) => !!v || 'Nome é obrigatório']"
                label="Nome"
                dense
              />
            </v-col>
            <v-col
              cols="12"
              class="py-0"
              v-if="party.Period && party.Period.length"
            >
              <v-select
                v-model="attraction.periodId"
                :items="party.Period"
                :rules="[(v) => !!v || 'Período é obrigatório']"
                label="Período"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
                <template v-slot:selection="{ item }">
                  <v-chip small>
                    {{ item.name }}
                  </v-chip>
                  <small>
                    {{ item.startDate | date("DD/MM HH:mm") }} até
                    {{ item.endDate | date("DD/MM HH:mm") }}
                  </small>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                      <small class="ml-2">
                        {{ item.startDate | date("DD/MM HH:mm") }} até
                        {{ item.endDate | date("DD/MM HH:mm") }}
                      </small>
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" class="pb-4 pt-0">
              <v-switch
                v-model="attraction.reveled"
                label="Atração revelada"
                :messages="[attraction.reveled ? 'Revelada' : 'Não revelada']"
                class="mt-0"
              />
            </v-col>
            <v-col cols="6" class="pb-4 pt-0">
              <v-switch
                v-model="attraction.timed"
                label="Possui horário"
                :messages="[
                  attraction.timed ? 'Possui horario' : 'Não possui horário',
                ]"
                class="mt-0"
              />
            </v-col>
            <template v-if="attraction.timed">
              <v-col cols="6" class="pb-0 pt-2">
                <v-text-field
                  v-model="attraction.start"
                  outlined
                  :rules="[
                    (v) => !!v || 'Inicio é obrigatório',
                    (v) =>
                      moment(v).isSameOrBefore(validDateRange.max) ||
                      'Inicio deve ser antes do fim do evento',
                    (v) =>
                      moment(v).isSameOrAfter(validDateRange.min) ||
                      'Inicio deve ser depois do inicio do evento',
                  ]"
                  label="Inicio"
                  type="datetime-local"
                  dense
                  :min="validDateRange.min"
                  :max="validDateRange.max"
                />
              </v-col>
              <v-col cols="6" class="pb-0 pt-2">
                <v-text-field
                  v-model="attraction.end"
                  outlined
                  :rules="[
                    (v) => !!v || 'Fim é obrigatório',
                    (v) =>
                      moment(v).isAfter(attraction.start) ||
                      'Fim deve ser depois do inicio',
                    (v) =>
                      moment(v).isSameOrBefore(validDateRange.max) ||
                      'Fim deve ser antes do fim do evento',
                    (v) =>
                      moment(v).isSameOrAfter(validDateRange.min) ||
                      'Fim deve ser depois do inicio do evento',
                  ]"
                  label="Fim"
                  type="datetime-local"
                  dense
                  :min="validDateRange.min"
                  :max="validDateRange.max"
                />
              </v-col>
            </template>

            <v-col cols="12" class="py-0">
              <v-textarea
                v-model="attraction.description"
                outlined
                label="Descrição"
                dense
                rows="3"
                hide-details="auto"
                counter="250"
              />
            </v-col>
          </v-row>
        </v-form>
        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="!valid && !image"
          @click="save"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ORGANIZATION from "@/services/admin/organization";
import moment from "moment";
const defaultAttraction = () => ({
  name: "",
  description: "",
  category: "OTHER",
  color: "indigo",
  timed: false,
  start: null,
  end: null,
  reveled: false,
  periodId: null,
});
import AttractionCategories from "@/definitions/AttractionCategories.json";
import AttractionRow from "../../../global/party/AttractionRow.vue";
import AttractionsList from "../../../global/party/AttractionsList.vue";

export default {
  components: { AttractionRow, AttractionsList },
  data: () => ({
    dialog: false,
    loading: false,
    valid: false,
    error: false,
    colors: [
      "purple",
      "indigo",
      "light-blue",
      "teal",
      "success",
      "lime",
      "amber",
      "warning",
      "error",
    ],
    attraction: defaultAttraction(),
    categories: AttractionCategories,
    image: null,
  }),

  methods: {
    moment,
    async removeImage() {
      try {
        this.loading = true;

        let party = this.party;
        await ORGANIZATION.party.attraction.update(
          party.organizationId,
          party.id,
          this.attraction.id,
          { image: null }
        );

        this.attraction.image = null;
        this.image = null;
        this.$emit("success");
      } catch (e) {
        this.error = e.message || "Erro ao remover imagem";
      } finally {
        this.loading = false;
      }
    },
    async uploadImage(id) {
      let party = this.party;
      return await ORGANIZATION.party.attraction.image(
        party.organizationId,
        party.id,
        id || this.attraction.id,
        this.image
      );
    },
    choiceImage() {
      this.$refs.image.click();
    },
    selectImage(e) {
      this.image = e.target.files[0];
    },
    selectColor(color) {
      this.attraction.color = color;
    },
    selectCategory(key) {
      this.attraction.category = key;
    },
    textClean(text) {
      return text
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/([^\w]+|\s+)/g, "-")
        .replace(/\-\-+/g, "-")
        .replace(/(^-+|-+$)/, "");
    },
    filterCategory(item, queryText) {
      const name = this.categories[item].text;
      const text = this.textClean(name);
      const query = this.textClean(queryText);
      return text.toLowerCase().indexOf(query.toLowerCase()) > -1;
    },
    open(data = {}) {
      this.attraction = Object.assign(defaultAttraction(), data);
      if (this.attraction.start)
        this.attraction.start = moment(this.attraction.start).format(
          "YYYY-MM-DDTHH:mm"
        );
      if (this.attraction.end)
        this.attraction.end = moment(this.attraction.end).format(
          "YYYY-MM-DDTHH:mm"
        );
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.form.validate();
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.loading = false;
        this.error = false;
        this.image = null;
        this.attraction = defaultAttraction();
      });
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        let party = this.party;
        let response;
        const attractionData = {
          ...this.attraction,
          start:
            this.attraction.timed && this.attraction.start
              ? moment(this.attraction.start).toISOString()
              : null,
          end:
            this.attraction.timed && this.attraction.end
              ? moment(this.attraction.end).toISOString()
              : null,
          periodId: this.attraction.periodId || null,
        };

        if (this.attraction.id) {
          response = await ORGANIZATION.party.attraction.update(
            party.organizationId,
            party.id,
            this.attraction.id,
            attractionData
          );
        } else {
          response = await ORGANIZATION.party.attraction.create(
            party.organizationId,
            party.id,
            attractionData
          );
        }

        if (this.image) await this.uploadImage(response.attraction?.id);

        this.$emit("success", response.attraction);
        this.close();
      } catch (e) {
        this.error = e.message || "Erro ao salvar atração";
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    validDateRange() {
      var min = this.party.date,
        max = this.party.endDate;

      if (
        this.party.Period &&
        this.party.Period.length &&
        this.attraction.periodId
      ) {
        const period = this.party.Period.find(
          (period) => period.id == this.attraction.periodId
        );
        min = period.startDate;
        max = period.endDate;
      }

      return {
        min: moment(min).format("YYYY-MM-DDTHH:mm"),
        max: moment(max).format("YYYY-MM-DDTHH:mm"),
      };
    },
    computedAttraction() {
      return {
        ...this.attraction,
        image: this.image
          ? URL.createObjectURL(this.image)
          : this.attraction.image,
      };
    },
  },
  watch: {
    "attraction.start"(value) {
      this.$refs.form.validate();
    },
  },
  mounted() {
    this.$parent.$on("attraction-modal", this.open);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
