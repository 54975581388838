<template>
  <div>
    <v-dialog v-model="isOpen" width="700" scrollable>
      <v-card v-if="!!guest">
        <v-card-title>
          <base-avatar
            v-if="!!guest.name"
            :seed="guest.id"
            :src="guest.photo"
            :size="50"
          />
          <v-avatar v-else color="grey lighten-3" size="50">
            <v-icon color="grey darken-1">mdi-account-question</v-icon>
          </v-avatar>
          <div class="pl-4 text-truncate">
            <p class="mb-0 text-subtitle-2 font-medium">
              {{ guest.name || "Conta não criada" }}
            </p>
            <p class="mb-0 text-caption">
              <a
                :href="
                  'https://wa.me/55' +
                  guest.phone.replace(/[^0-9]/g, '') +
                  '?text=Olá ' +
                  (guest.name || '') +
                  '!'
                "
                target="_blank"
                >{{ guest.phone }}</a
              >
            </p>
            <p class="mb-0 text-caption">
              <a :href="'mailto:' + guest.email" target="_blank">{{
                guest.email
              }}</a>
            </p>
          </div>
        </v-card-title>

        <v-card-text>
          <guest-tickets :tickets="guest.TicketOwner" @refresh="refresh" />
        </v-card-text>

        <v-card-actions class="justify-center">
          <v-btn text :disabled="loading" @click="close"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <transfer-ticket @success="refresh(true)" :party="party" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import GuestTickets from "./GuestTickets.vue";

import TransferTicket from "../TransferTicket.vue";

export default {
  components: { TransferTicket, GuestTickets },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    isOpen: false,
    loading: false,
    guest: null,
  }),

  methods: {
    refresh(close = false) {
      this.$emit("refresh");
      if (close) this.close();
    },
    open(guest) {
      this.isOpen = true;
      this.guest = guest;
    },
    close() {
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.$nextTick(() => (this.guest = null));
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  watch: {},
  mounted() {
    this.$parent.$on("guest-profile", this.open);
  },
};
</script>

<style></style>
