<template>
  <div class="pa-1">
    <v-card outlined class="mb-3">
      <v-card-title class="text-16 pb-1"> Compra </v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-around flex-wrap">
          <div class="px-2 mb-2 text-center">
            <p class="mb-0 text-subtitle-2 font-medium">Data da compra</p>
            <p class="mb-0 text-caption">
              {{ ticket.createdAt | date("DD/MM/YYYY HH:mm") }}
            </p>
          </div>
          <div class="px-2 mb-2 text-center">
            <p class="mb-0 text-subtitle-2 font-medium">Promoter</p>
            <p class="mb-0 text-caption">
              {{ ticket.Seller?.name || "Não informado" }}
            </p>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined class="mb-3">
      <v-card-title class="text-16 pb-1">
        Pagamento
        <v-spacer></v-spacer>
        <v-btn
          x-small
          outlined
          :loading="loading"
          @click="refreshTicketPayment"
          :color="PAYMENT.ticketStatus[ticket.Payment.status].color"
        >
          {{ PAYMENT.ticketStatus[ticket.Payment.status].text }}
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div class="d-flex justify-space-around flex-wrap">
          <div class="px-2 mb-2 text-center">
            <p class="mb-0 text-subtitle-2 font-medium">Meio de pagamento</p>
            <p class="mb-0 text-caption">
              {{ PAYMENT.paymentMethod[ticket.Payment.paymentMethod].text }}
            </p>
          </div>
          <div class="px-2 mb-2 text-center">
            <p class="mb-0 text-subtitle-2 font-medium">Forma de pagamento</p>
            <p class="mb-0 text-caption">
              {{ PAYMENT.paymentType[ticket.Payment.paymentType].text }}
            </p>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-row>
      <template
        v-if="ticket.Payment.status === 'succeeded' && hasPermission(128)"
      >
        <v-col cols="12" sm="6" class="pt-0 pb-2">
          <v-btn
            color="primary"
            @click="transfer(ticket)"
            :loading="loading"
            small
            block
          >
            <v-icon small left>mdi-account-switch</v-icon> transferir
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="pt-0 pb-2">
          <v-btn
            color="warning"
            @click="refund(ticket)"
            :loading="loading"
            small
            block
          >
            <v-icon small left>mdi-cash-refund</v-icon> Reembolsar
          </v-btn>
        </v-col>
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PAYMENT from "@/utils/payment";
import TICKET from "@/services/admin/ticket";

export default {
  data: () => ({
    loading: false,
    PAYMENT,
  }),
  methods: {
    transfer(ticket) {
      this.$root.$emit("transfer-ticket", ticket);
    },
    refund(ticket) {
      this.$root.$emit("refund-ticket", ticket);
    },
    async refreshTicketPayment() {
      if (!this.hasPermission(0)) return;
      try {
        this.loading = true;
        const response = await TICKET.updateTicketPayment(
          this.selectedOrganization.id,
          this.$route.params.partyId,
          this.ticket.id
        );
        console.log(response);
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  props: {
    ticket: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
