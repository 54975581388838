<template>
  <div>
    <div>
      <h6 class="mb-0">Selecione os vendedores</h6>
      <v-text-field
        v-model="search"
        label="Pesquisar"
        outlined
        dense
        class="my-2"
        hide-details
      ></v-text-field>
    </div>
    <v-data-table
      v-model="sellers"
      :headers="sellersOptions.headers"
      :items="members"
      item-key="id"
      :search="search"
      :items-per-page="members.length"
      mobile-breakpoint="0"
      sort-by="name"
      hide-default-footer
      show-select
    >
      <template v-slot:item.name="{ item }">
        <base-avatar
          :src="item.photo"
          :seed="item.id"
          :size="22"
          class="mr-2"
        />
        {{ item.name || "Não cadastrado" }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  emits: ["input"],
  data: () => ({
    search: "",
    sellersOptions: {
      headers: [
        { text: "Nome", value: "name" },
        { text: "WhatsApp", value: "phone" },
      ],
    },
  }),
  methods: {
    
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    sellers: {
      get: function () {
        return this.value;
      },
      set: function (value) {
        this.$emit("input", value);
      },
    },
  },
  props: {
    members: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    ticketBlock: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style></style>
