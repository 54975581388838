<template>
  <v-row>
    <v-col cols="12" md="4">
      <party-map
        :party="{
          locationReveled: basic?.locationReveled,
          Address: basic?.fullAddress,
        }"
      ></party-map>
    </v-col>
    <v-col cols="12" md="8">
      <place-search class="flex-grow-1" outlined v-model="basic.address" />
      <v-switch
        v-model="basic.locationReveled"
        :label="basic.locationReveled ? 'Local revelado' : 'Local revelado'"
        :messages="
          basic.locationReveled ? ['Local revelado'] : ['Local não revelado']
        "
      ></v-switch>
    </v-col>
  </v-row>
</template>

<script>
import PARTY from "@/services/admin/party";
import PlaceSearch from "../../../../views/global/PlaceSearch.vue";
import PartyMap from "../../../shop/PartyMap.vue";

export default {
  components: { PlaceSearch, PartyMap },
  data() {
    var basic = {
      fullAddress: null,
      address: null,
      locationReveled: true,
    };

    if (this.party) {
      basic.fullAddress = this.party.Address ? this.party.Address : null;
      basic.address = this.party.Address
        ? this.formatAddress(this.party.Address)
        : null;
      basic.locationReveled =
        this.party.locationReveled !== undefined
          ? this.party?.locationReveled
          : true;
    }

    return {
      basic,
      rules: {
        name: [
          (v) => !!v || "Name é obrigatório",
          (v) =>
            (v && v.length <= 30) || "Name deve ter menos de 30 caracteres",
        ],
      },
    };
  },
  methods: {
    formatAddress(address) {
      return {
        ...address,
        name: address.name,
        description: `${address.street}, ${address.number} - ${address.neighborhood}, ${address.city} - ${address.state}`,
        data: null,
      };
    },
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,
        address: this.basic.address,
        locationReveled: this.basic.locationReveled,
      });
    },
  },
  mounted() {},
  watch: {
    "basic.address": {
      handler: function (val) {
        this.basic.fullAddress = val;
      },
      deep: true,
    },
    party: {
      handler: function (val) {
        this.basic = {
          address: val.Address ? this.formatAddress(val.Address) : null,
          locationReveled:
            val.locationReveled !== undefined ? val.locationReveled : true,
        };
      },
      deep: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
