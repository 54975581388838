<template>
  <v-card outlined :loading="loading">
    <v-card-text class="d-flex flex-wrap justify-space-around px-8">
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">{{ reducedPayments.tickets }}</h6>
        <span class="text-caption">Ingressos Vendidos</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{ (reducedPayments.amount / approvedPayments.length) | currency }}
        </h6>
        <span class="text-caption">Tíquete Médio</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">{{ reducedPayments.amount | currency }}</h6>
        <span class="text-caption">Vendas Brutas</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{ reducedPayments.fee | currency }}
          <small>(≈{{ (feePercentage || 0).toFixed(1) }}%)</small>
        </h6>
        <span class="text-caption">Taxas Pagas</span>
      </div>
      <div class="text-center px-4">
        <h6 class="mt-2 mb-0">
          {{ (reducedPayments.amount - reducedPayments.fee) | currency }}
        </h6>
        <span class="text-caption">Valor Líquido</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    approvedPayments() {
      return this.data.payments.filter((p) => p.status === "succeeded");
    },
    reducedPayments() {
      return this.approvedPayments.reduce(
        (acc, p) => {
          const { integrationFee, platformFee } = p;
          acc.tickets += p.Ticket.length;
          acc.amount += parseFloat(p.amount);
          acc.fee +=
            parseFloat(integrationFee || 0) + parseFloat(platformFee || 0);
          return acc;
        },
        {
          tickets: 0,
          amount: 0,
          fee: 0,
        }
      );
    },
    feePercentage() {
      return (this.reducedPayments.fee / this.reducedPayments.amount) * 100;
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
