<template>
  <v-dialog v-model="show" :persistent="!!loading" max-width="600px">
    <v-card>
      <v-card-title class="headline">Salvar mapa</v-card-title>

      <v-card-text>
        <template v-if="error">
          <v-alert type="error" border="left">
            Ocorreu um erro ao salvar o mapa
          </v-alert>
        </template>
        <template v-else-if="loading">
          <v-progress-linear :value="process" />
          <v-alert type="info" text dense>
            {{ steps[currentStep] }}
          </v-alert>
        </template>
        <template v-else>
          <v-alert type="success" border="left">
            Mapa salvo com sucesso
          </v-alert>
        </template>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn color="primary" text :disabled="loading" @click="show = false">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PARTY from "@/services/admin/party";

export default {
  data: () => ({
    show: false,
    loading: false,
    error: false,
    currentPosition: null,
    currentStep: 0,
    totalPositions: 0,
  }),

  methods: {
    dataURItoBlob(dataURI) {
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);
      var ia = new Uint8Array(ab);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },
    async saveMap(file, options) {
      if (!file) {
        this.currentStep = 1;
        return PARTY.map.update(this.party.organizationId, this.party.id, {
          options,
        });
      } else {
        return PARTY.map.create(
          this.party.organizationId,
          this.party.id,
          file,
          {
            options,
          }
        );
      }
    },
    async savePositions(positions) {
      for (const [index, position] of positions.entries()) {
        this.currentPosition = [index, position];
        if (position.id) {
          if (position.deleted)
            await PARTY.map.position.delete(
              this.party.organizationId,
              this.party.id,
              position.id
            );
          else if (position.default)
            await PARTY.map.position.setDefault(
              this.party.organizationId,
              this.party.id,
              position.id
            );
        } else {
          const file = this.dataURItoBlob(position.image);
          await PARTY.map.position.create(
            this.party.organizationId,
            this.party.id,
            file,
            {
              ...position,
              image: undefined,
            }
          );
        }
      }
      this.currentPosition = null;
    },
    async updateMapDetails() {
      const { map } = await PARTY.map.get(
        this.party.organizationId,
        this.party.id
      );
      this.$emit("map", map);
    },
    async save({ file, positions, options }) {
      try {
        this.show = true;
        this.loading = true;
        this.error = false;
        this.totalPositions = positions.length;

        this.currentStep = 0;
        await this.saveMap(file, options);

        this.currentStep = 2;
        await this.savePositions(positions);

        this.currentStep = 3;
        await this.updateMapDetails();

        this.loading = false;
      } catch (e) {
        console.error(e);
        this.error = true;
        this.loading = false;
      }
    },
  },
  computed: {
    process() {
      const totalSteps = 4 + (this.totalPositions || 0);
      const currentPositionIndex = this.currentPosition
        ? this.currentPosition[0]
        : 0;
      const currentStep = this.currentStep + 1 + currentPositionIndex;

      return (this.loading ? currentStep / totalSteps : 1) * 100;
    },
    steps() {
      const [i, position] = this.currentPosition || [0, null];
      return [
        "Enviando arquivo",
        "Salvar configurações",
        `Atualizar posição "${position?.name}" (${i + 1}/${
          this.totalPositions || 0
        })`,
        "Finalizar",
      ];
    },
  },
  mounted() {
    this.$parent.$on("save", this.save);
  },

  props: {
    party: {},
  },
};
</script>

<style>
</style>