<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
    eager
  >
    <v-card>
      <v-card-title>
        {{ seller?.id ? `Editar vendedor` : "Adicionar vendedor" }}
      </v-card-title>
      <v-card-text class="pt-1" v-if="seller">
        <v-card outlined class="pa-2 rounded-xl d-flex gap-2 mb-4">
          <baseAvatar
            v-if="!seller.photo && seller.name"
            :size="50"
            :seed="seller.id"
          />
          <v-avatar v-else size="50">
            <img v-if="seller.name" :src="seller.photo" />
            <v-icon v-else x-large>mdi-account-question</v-icon>
          </v-avatar>
          <div>
            <b>{{ seller.name || "Conta não criada" }}</b>
            <p class="mb-0">{{ seller.phone }}</p>
          </div>
          <v-spacer />
          <div
            v-if="seller.permissions"
            class="d-flex flex-column align-end justify-center gap-1"
          >
            <v-chip
              label
              x-small
              v-if="seller.permissions.online"
              color="success"
            >
              <v-icon left x-small> mdi-web </v-icon>
              Venda Online
            </v-chip>
            <v-chip
              label
              x-small
              v-if="seller.permissions.offline"
              color="info"
            >
              <v-icon left x-small> mdi-account-cash </v-icon>
              Venda Em Dinheiro
            </v-chip>
          </div>
        </v-card>

        <v-alert :color="!!seller.SellerProfile ? 'success' : 'info'" dense>
          <div class="white--text d-flex justify-space-between">
            <span>
              {{ !!seller.SellerProfile ? "Exibir" : "Não exibir" }} perfil do
              vendedor na página do evento
            </span>
            <v-btn
              @click="editSellerProfile()"
              x-small
              color="white"
              class="mt-0 pt-0 black--text"
              elevation="0"
            >
              Gerenciar
            </v-btn>
          </div>
        </v-alert>

        <v-form v-model="valid" @submit.prevent ref="form">
          <h6>Pode vender</h6>
          <div v-for="(tg, i) in ticketGroups" :key="tg.id">
            <v-divider v-if="i !== 0" class="my-2"></v-divider>
            <div class="d-flex justify-space-between">
              <h6 class="text-14">{{ tg.name }}</h6>
              <v-btn small text @click.stop="toggleAll(tg.TicketBlock)">
                {{
                  tg.TicketBlock.some(
                    (tb) => !(seller.TicketBlockSeller || []).includes(tb.id)
                  )
                    ? "Marcar todos"
                    : "Desmarcar todos"
                }}
              </v-btn>
            </div>

            <v-row class="mx-0" no-gutters>
              <v-col cols="12" sm="6" v-for="tb in tg.TicketBlock" :key="tb.id">
                <v-checkbox
                  v-model="seller.TicketBlockSeller"
                  :value="tb.id"
                  :label="tb.name"
                  dense
                  multiple
                  hide-details
                  class="mt-0 pt-0"
                />
              </v-col>
            </v-row>
          </div>
        </v-form>

        <v-alert type="error" v-if="error" class="mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="ml-1"
          color="primary"
          :loading="loading"
          :disabled="!valid"
          @click="save"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import SELLER from "@/services/admin/party/seller";

export default {
  data: () => ({
    dialog: false,
    error: false,
    valid: false,
    loading: false,
    ticketGroups: false,
    seller: null,
  }),

  methods: {
    editSellerProfile() {
      this.$root.$emit("seller-profile-modal", this.seller);
      this.close();
    },

    toggleAll(ticketBlocks) {
      const sellerTicketBlocks = this.seller.TicketBlockSeller || [];

      ticketBlocks = ticketBlocks.map((p) => p.id);
      const hasAll = ticketBlocks.every((p) => sellerTicketBlocks.includes(p));

      if (hasAll) {
        this.seller.TicketBlockSeller = sellerTicketBlocks.filter(
          (p) => !ticketBlocks.includes(p)
        );
      } else {
        this.seller.TicketBlockSeller = [
          ...new Set(sellerTicketBlocks.concat(ticketBlocks)),
        ];
      }
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        await SELLER.update(
          this.party.organizationId,
          this.party.id,
          this.seller.id,
          { ticketBlocks: this.seller.TicketBlockSeller }
        );

        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    async getTicketGroups() {
      try {
        const response = await TICKET.getTickets(
          this.party.organizationId,
          this.party.id
        );
        this.ticketGroups = response.ticketGroups;
      } catch (e) {
        this.ticketGroups = false;
      }
    },
    open(seller = {}) {
      this.seller = Object.assign({}, seller);

      if (seller.TicketBlockSeller)
        this.seller.TicketBlockSeller = seller.TicketBlockSeller.map(
          (tb) => tb.id
        );

      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.$refs.form.reset();
      this.dialog = false;
      this.seller = null;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$parent.$on("seller-modal", this.open);
  },
  created() {
    this.getTicketGroups();
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
