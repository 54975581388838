<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="d-flex">
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="loading"
            :to="{ name: 'admin.party.create' }"
            id="btn-party-create"
          >
            <v-icon left> mdi-plus </v-icon>
            Evento
          </v-btn>
        </div>
        <v-progress-linear v-if="loading && !parties.length" indeterminate />
      </v-col>
      <v-col md="12">
        <transition-group appear name="fade-up" tag="div">
          <v-data-iterator
            key
            :items="partiesSorted"
            :items-per-page="itemsPerPage"
            :page="page"
            hide-default-footer
          >
            <template v-slot:default="props">
              <div class="d-flex flex-column" style="gap: 1rem">
                <party-router-links
                  v-for="item in props.items"
                  :key="item.id"
                  :party="item"
                  :loading="loading"
                  class="px-3"
                />
              </div>
            </template>
            <template v-slot:footer>
              <v-row class="mt-2 mr-1" align="center" justify="center">
                <v-spacer />

                <span class="mr-4 grey--text">
                  Página {{ page }} de {{ numberOfPages }}
                </span>
                <v-btn
                  x-small
                  fab
                  color="primary darken-3"
                  class="mr-1"
                  :disabled="page <= 1"
                  @click="() => (page > 1 ? page-- : null)"
                >
                  <v-icon>mdi-chevron-left</v-icon>
                </v-btn>
                <v-btn
                  x-small
                  fab
                  color="primary darken-3"
                  class="ml-1"
                  :disabled="page >= numberOfPages"
                  @click="() => (page < numberOfPages ? page++ : null)"
                >
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </v-row>
            </template>
          </v-data-iterator>
        </transition-group>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import PartyQrCode from "../../../components/admin/party/PartyQrCode.vue";
import PartyRouterLinks from "../../../components/admin/party/PartyRouterLinks.vue";
import PartySort from "@/utils/partySortFunction";

export default {
  components: { PartyQrCode, PartyRouterLinks },
  metaInfo: {
    title: "Eventos",
  },
  data() {
    return {
      loading: false,
      itemsPerPage: 3,
      page: 1,
    };
  },
  methods: {
    ...mapActions("auth", ["getPermissions"]),
    ...mapActions("organization", ["updateParties"]),
    async getParties() {
      try {
        this.loading = true;
        await this.updateParties();
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },

  computed: {
    ...mapGetters("auth", ["hasPermission"]),
    ...mapGetters("organization", ["selectedOrganization", "parties"]),
    partiesSorted() {
      return this.parties.sort(PartySort);
    },
    numberOfPages() {
      return Math.ceil(this.parties.length / this.itemsPerPage);
    },
  },
  watch: {
    "selectedOrganization.id"() {
      this.getParties();
    },
  },
  async mounted() {
    await this.getParties();
  },
};
</script>
