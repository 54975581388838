<template>
  <div>
    <v-row dense>
      <v-col cols="12" sm="7" lg="6">
        <v-card class="rounded-xl" id="canvasContainer" light>
          <v-snackbar
            v-model="snackbar.show"
            :color="snackbar.color"
            absolute
            style="z-index: 5"
            timeout="40000"
            top
          >
            {{ snackbar.text }}
          </v-snackbar>
          <div
            class="rounded-t-xl"
            style="
              overflow: hidden;
              background: #f5f5f5;
              width: 100%;
              height: 400px;
            "
            :style="{
              width: canvasSize.width + 'px',
              height: canvasSize.height + 'px',
            }"
            id="canvas"
          />
          <!-- :style="{
              width: canvasSize.width + 'px',
              height: canvasSize.height + 'px',
            }" -->
          <div class="grey lighten-3 py-2 px-4">
            <div class="float-right">
              <v-btn
                @click="zoomOut"
                :disabled="loading || map?.getZoom() <= editorProps.minZoom"
                small
                icon
              >
                <v-icon small>mdi-magnify-minus</v-icon>
              </v-btn>
              <v-btn
                @click="zoomIn"
                :disabled="loading || map?.getZoom() >= editorProps.maxZoom"
                small
                icon
              >
                <v-icon small>mdi-magnify-plus</v-icon>
              </v-btn>
            </div>
            <div class="d-flex flex-wrap align-center gap-x-4 gap-y-1">
              <div class="d-flex align-center gap-2">
                <v-avatar size="12" style="border: 2px solid black" />
                <p class="mb-0 text-center lh-1">Mesa selecionada</p>
              </div>
              <div class="d-flex align-center gap-2">
                <v-avatar size="12" style="border: 2px solid red" />
                <p class="mb-0 text-center lh-1">Venda Online Desabilitada</p>
              </div>
              <div class="d-flex align-center gap-2">
                <v-avatar size="12" style="border: 2px solid blue" />
                <p class="mb-0 text-center lh-1">Vendido</p>
              </div>
            </div>
          </div>
        </v-card>
        <p class="mb-0 text-center">
          <small v-html="hints[selectedHint]" />
        </p>
      </v-col>
      <v-col cols="12" sm="5" lg="6">
        <v-card elevation="0" outlined rounded="lg">
          <v-tabs v-model="mode" grow>
            <v-tab tab-value="map">
              <v-icon left small>mdi-map</v-icon>
              Mapa
            </v-tab>
            <v-tab tab-value="table">
              <v-icon left small>mdi-table-chair</v-icon>
              Mesa
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="mode">
            <v-tab-item value="map">
              <v-card-text>
                <div v-if="typeof partyMap.backgroundUrl === 'string'">
                  <v-btn
                    small
                    color="error"
                    @click="partyMap.backgroundUrl = null"
                    :disabled="loading"
                    block
                  >
                    Remover Imagem de Fundo
                  </v-btn>
                </div>

                <div v-else>
                  <v-file-input
                    label="Imagem de Fundo"
                    dense
                    outlined
                    accept="image/*"
                    v-model="partyMap.backgroundUrl"
                    show-size
                    hide-details
                    :disabled="loading"
                  />
                  <v-alert type="info" dense text class="mt-4">
                    A imagem deve ter a proporção 4:3.<br />
                    <small> Tamanho recomendado: 800x600px </small>
                  </v-alert>
                </div>
                <v-divider class="my-4" />

                <div>
                  <div class="d-flex justify-space-between align-center">
                    <h6 class="mb-0">Grupo de Mesas</h6>
                    <v-btn
                      small
                      color="primary"
                      @click="addTableGroup"
                      :disabled="loading"
                    >
                      <v-icon left>mdi-plus</v-icon>
                      Grupo
                    </v-btn>
                  </div>
                </div>

                <!-- Grupos -->
                <v-card
                  v-for="group in partyMap.Groups"
                  :key="group.id"
                  outlined
                  rounded="lg"
                  class="pa-3 mt-2"
                >
                  <h5 class="d-flex gap-2 align-center mb-2">
                    <v-menu
                      :close-on-content-click="false"
                      top
                      :disabled="loading"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <!-- <v-badge
                      v-bind="attrs"
                      v-on="on"
                      inline
                      :color="group.color"
                    /> -->
                        <v-avatar
                          v-bind="attrs"
                          v-on="on"
                          :style="{ background: group.color }"
                          class="white--text"
                          size="24px"
                        >
                          <v-icon x-small>mdi-palette</v-icon>
                        </v-avatar>
                      </template>

                      <v-color-picker
                        v-model="group.color"
                        dot-size="25"
                        mode="rgba"
                      />
                    </v-menu>
                    <b>{{ group.name }}</b>
                  </h5>

                  <!-- Opções -->
                  <v-text-field
                    v-model="group.name"
                    :disabled="loading"
                    label="Nome"
                    hide-details="auto"
                    class="mb-2"
                    outlined
                    dense
                    hint="Ex: Mesa, Poltrona, Bistrô, etc."
                  />
                  <div class="d-flex align-end gap-2 mb-4">
                    <div>
                      <p class="mb-0">Formato</p>
                      <v-btn-toggle mandatory :disabled="loading">
                        <v-btn
                          v-for="format in editorProps.formats"
                          :key="format"
                          :value="format"
                          small
                          :color="group.options.format === format ? '' : ''"
                          @click="group.options.format = format"
                        >
                          <v-icon small>{{ formatsIcons[format] }}</v-icon>
                        </v-btn>
                      </v-btn-toggle>
                    </div>
                    <v-text-field
                      v-model.number="group.options.size"
                      label="Tamanho no Mapa"
                      :disabled="loading"
                      type="number"
                      hide-details
                      outlined
                      dense
                      min="2"
                      max="20"
                    />
                  </div>

                  <v-divider class="my-2" />
                  <div>
                    <div class="mb-2 d-flex justify-space-between align-center">
                      <h6 class="mb-0">
                        {{ group.Tables.length }} Mesa{{
                          group.Tables.length != 1 ? "s" : ""
                        }}
                      </h6>

                      <v-btn
                        @click="addTable(group.id)"
                        color="primary"
                        x-small
                        elevation="0"
                        :disabled="loading"
                      >
                        <v-icon small left>mdi-plus</v-icon>
                        Mesa
                      </v-btn>
                    </div>
                    <v-text-field
                      v-model.number="group.capacity"
                      outlined
                      dense
                      label="Capacidade"
                      type="number"
                      min="1"
                      max="20"
                      hide-details="auto"
                      :disabled="loading"
                    />
                  </div>
                </v-card>
              </v-card-text>
            </v-tab-item>
            <v-tab-item value="table">
              <v-card-text>
                <div v-if="!drag.draggedTable">
                  <p class="mb-0 text-center">Selecione uma mesa no mapa</p>
                </div>
                <div v-else>
                  <div class="d-flex align-center gap-2 mb-4">
                    <v-avatar
                      :color="drag.draggedTable.group.color"
                      size="24px"
                      :class="
                        drag.draggedTable.group.options.format === 'square'
                          ? 'rounded-0'
                          : ''
                      "
                    />

                    <h6 class="mb-0">
                      {{ drag.draggedTable.name }}
                    </h6>
                  </div>

                  <v-text-field
                    v-model="drag.draggedTable.name"
                    :disabled="loading"
                    label="Nome"
                    hide-details="auto"
                    class="mb-2"
                    outlined
                    dense
                  />
                  <v-checkbox
                    v-model="drag.draggedTable.sellOnline"
                    :disabled="loading"
                    label="Vender Online"
                    hide-details
                    class="mb-2"
                    outlined
                    dense
                  />
                </div>
              </v-card-text>
            </v-tab-item>
          </v-tabs-items>
          <v-card-actions>
            <v-spacer />
            <v-btn color="success" @click="save" :loading="loading">
              Salvar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";

const DEFAULT_GROUP_OPTIONS = () => ({
  format: "circle",
  size: 24,
  type: "Mesa",
});

const DEFAULT_TABLE_OPTIONS = () => ({
  position: {
    x: 0.4,
    y: 0.4,
  },
});

export default {
  metaInfo: {
    meta: [
      {
        name: "viewport",
        content: "user-scalable=no, width=device-width, initial-scale=1",
      },
    ],
  },
  name: "TableMapEditor",
  props: {
    tableMap: {
      type: Object,
      required: true,
    },
    party: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    map: null,
    ctx: null,
    mode: "map",
    partyMap: { backgroundUrl: null, Groups: [] },
    snackbar: {
      show: false,
      text: "",
      color: "",
    },

    drag: {
      isDragging: false,
      draggedTable: null,
    },
    formatsIcons: {
      square: "mdi-square",
      circle: "mdi-circle",
      hexagon: "mdi-hexagon",
    },
    editorProps: {
      minZoom: -2,
      maxZoom: 5,
      zoomSnap: 0,
      arrowStep: 10,
      minSize: 8,
      maxSize: 96,
      formats: [
        "circle",
        "square",
        //"hexagon"
      ],
      colors: [
        "#FFB6C1FF",
        "#FFA500FF",
        "#FFFF00FF",
        "#98FB98FF",
        "#00FFFFFF",
        "#87CEEBFF",
      ],
    },
    canvasSize: {
      aspectRatio: 4 / 3,
      width: 800,
      height: 600,
    },
    selectedHint: 0,
    hints: [
      "Dica: Use <code>Ctrl</code> + <code>D</code> para adicionar uma nova mesa ao grupo.",
      "Dica: Use <code>+</code> e <code>-</code> para aumentar ou diminuir o tamanho de uma mesa.",
      "Dica: Use <code>Tab</code> para selecionar a próxima mesa.",
      "Dica: Use as <code>Setas</code> do teclado para mover a mesa selecionada.",
      "Dica Pro: Use <code>Ctrl</code> + <code>Shift</code> + <code>Setas</code> para fazer movimentos mais precisos.",
    ],
  }),
  methods: {
    // Functional
    addTableGroup() {
      this.partyMap.Groups.push({
        id: this.partyMap.Groups.length + 1,
        name: `Grupo ${this.partyMap.Groups.length + 1}`,
        color:
          this.editorProps.colors[
            this.partyMap.Groups.length % this.editorProps.colors.length
          ],
        capacity: 1,
        options: DEFAULT_GROUP_OPTIONS(),
        Tables: [],
      });
      this.addTable(this.partyMap.Groups.length);
    },

    addTable(groupId, position) {
      const group = this.partyMap.Groups.find((g) => g.id === groupId);
      const table = {
        id: group.Tables.length + 1,
        position: Object.assign({ x: 50, y: 50 }, position),
        name: group.Tables.length + 1,
        sellOnline: true,
      };

      group.Tables.push(table);

      this.drag.draggedTable = table;
      this.drag.draggedTable.group = group;
    },

    deleteTable(groupId, tableId) {
      try {
        const group = this.partyMap.Groups.find((g) => g.id === groupId);
        const tableIndex = group.Tables.findIndex((t) => t.id === tableId);
        if (tableIndex === -1) return;
        if (group.Tables[tableIndex].Ticket) throw new Error("Mesa ocupada");

        if (this.drag.draggedTable?.id === tableId)
          this.drag.draggedTable = null;
        group.Tables.splice(tableIndex, 1);

        this.snackbar = {
          show: true,
          text: "Mesa removida com sucesso",
          color: "success",
        };

        // this.draw();
      } catch (error) {
        this.snackbar = {
          show: true,
          text: error.message || "Erro ao deletar mesa",
          color: "error",
        };
      }
    },
    zoomIn() {
      console.log(this.map);
      this.map.zoomIn();
    },
    zoomOut() {
      this.map.zoomOut();
    },

    // Draw and Canvas
    calculateCanvasSize() {
      const { aspectRatio } = this.canvasSize;
      const { offsetWidth } = document.querySelector("#canvasContainer");
      const canvasWidth = offsetWidth;
      const canvasHeight = canvasWidth / aspectRatio;
      this.canvasSize = {
        aspectRatio,
        width: canvasWidth,
        height: canvasHeight,
      };
    },
    async init() {
      this.calculateCanvasSize();
      this.map = L.map("canvas", {
        crs: L.CRS.Simple,
        minZoom: this.editorProps.minZoom,
        maxZoom: this.editorProps.maxZoom,
        zoomSnap: this.editorProps.zoomSnap,
        zoom: -2,

        zoomControl: false,
        keyboard: false,
        // tilePane: 5,
        // overlayPane: 10,
        // shadowPane: 15,
        // markerPane: 20,
        // tooltipPane: 25,
        // popupPane: 30,
      });
      this.map.attributionControl.setPrefix(false);
      this.map.attributionControl.addAttribution(this.party.name);

      const bounds = [
        [0, 0],
        [this.canvasSize.height, this.canvasSize.width],
      ];
      this.map.setMaxBounds(bounds);
      try {
        await this.setBackground();
        this.drawTables();
      } catch (e) {
        console.log(e);
      }
    },
    async draw() {
      this.drawTables();
      await this.setBackground();
    },
    drawTables() {
      this.removeTableLayers();
      this.tables.forEach((table) => this.drawTable(table.group, table));
    },

    drawTable(group, table) {
      const size = group.options.size / 2;
      const { x, y } = table.position;
      const format = group.options.format;
      const selected =
        this.drag.draggedTable?.id === table.id &&
        this.drag.draggedTable?.group?.id === group.id;
      var el;
      var borderColor = group.color;
      if (selected) borderColor = "black";
      else if (table.Ticket) borderColor = "blue";
      else if (!table.sellOnline) borderColor = "red";

      if (format == "square")
        el = L.rectangle(
          [
            [x - size, y - size],
            [x + size, y + size],
          ],
          {
            color: borderColor,
            fillColor: group.color,
            fillOpacity: 1,
          }
        );
      else
        el = L.circle([x, y], {
          color: borderColor,
          fillOpacity: 1,
          fillColor: group.color,
          radius: size,
        });

      el.bindTooltip(
        `<b>${group.name} • ${table.name}</b><br /> <small>${group.capacity} pessoas</small>`,
        {
          className: "rounded-lg",
        }
      ).addTo(this.map);

      el.on("click", () => this.selectTable(table, group));
    },
    setBackground() {
      return new Promise((resolve, reject) => {
        try {
          const { backgroundUrl } = this.partyMap;
          const img = new Image();
          const url =
            backgroundUrl instanceof File
              ? URL.createObjectURL(backgroundUrl)
              : backgroundUrl;
          img.src = url;
          img.onload = () => {
            this.removeImageLayers();
            const bounds = [
              [0, 0],
              [img.height, img.width],
            ];

            const image = L.imageOverlay(url, bounds);
            image.addTo(this.map);
            const map = this.map;
            this.map.on("drag", function () {
              map.panInsideBounds(bounds, { animate: false });
            });

            this.map.setMaxBounds(bounds);
            this.map.fitBounds(bounds);
            this.map.options.minZoom = this.map.getZoom();

            resolve();
          };
          img.onerror = reject;
        } catch (error) {
          console.log(error);
          reject(error);
        }
      });
    },

    removeImageLayers() {
      this.map.eachLayer((layer) => {
        if (layer instanceof L.ImageOverlay) {
          this.map.removeLayer(layer);
        }
      });
    },
    removeTableLayers() {
      this.map.eachLayer((layer) => {
        if (layer instanceof L.Circle || layer instanceof L.Rectangle) {
          layer.off("click");

          this.map.removeLayer(layer);
        }
      });
    },

    selectTable(table, group) {
      this.drag.draggedTable = null;
      this.$nextTick(() => {
        this.drag.draggedTable = table;
        this.drag.draggedTable.group = group;
        this.drawTables();
      });
    },
    // Utils
    relative({ x, y }, absolute = false) {
      return {
        x: x * this.canvasSize.width,
        y: y * this.canvasSize.height,
      };
    },
    getMousePos(canvas, e) {
      const rect = canvas.getBoundingClientRect();
      return {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
    },
    onKeyDown(event) {
      console.log(event);
      const { draggedTable } = this.drag;
      if (!draggedTable) return;

      const { key } = event;
      const holdCtrl = event.ctrlKey || event.metaKey;
      const holdShift = event.shiftKey;
      const holdAlt = event.altKey;
      const stepMultiplier =
        holdShift && (holdCtrl || holdAlt)
          ? 0.1
          : holdShift
          ? 0.5
          : holdCtrl
          ? 2
          : 1;
      const step = this.editorProps.arrowStep * stepMultiplier;
      console.log(step);
      if (holdCtrl && key === "d") {
        event.preventDefault();
        this.addTable(draggedTable.group.id, {
          x: draggedTable.position.x,
          y:
            draggedTable.position.y +
            draggedTable.group.options.size +
            this.editorProps.arrowStep,
        });
      } else if (key === "Delete") {
        event.preventDefault();
        this.deleteTable(draggedTable.group.id, draggedTable.id);
      } else if (key === "=") {
        event.preventDefault();
        if (holdCtrl) {
          this.zoomIn();
        } else {
          draggedTable.group.options.size = Math.min(
            draggedTable.group.options.size + 1,
            this.editorProps.maxSize
          );
        }
      } else if (key === "-") {
        event.preventDefault();
        if (holdCtrl) {
          this.zoomOut();
        } else {
          draggedTable.group.options.size = Math.max(
            draggedTable.group.options.size - 1,
            this.editorProps.minSize
          );
        }
      } else if (key === "Tab") {
        event.preventDefault();
        const index = this.tables.findIndex((g) => g.id === draggedTable.id);

        const newIndex = index + (holdShift ? -1 : 1);
        if (newIndex < 0)
          this.drag.draggedTable = this.tables[this.tables.length - 1];
        else
          this.drag.draggedTable = this.tables[newIndex % this.tables.length];
      } else if (key === "ArrowUp") {
        event.preventDefault();
        draggedTable.position.x += step;
      } else if (key === "ArrowDown") {
        event.preventDefault();
        draggedTable.position.x -= step;
      } else if (key === "ArrowLeft") {
        event.preventDefault();
        draggedTable.position.y -= step;
      } else if (key === "ArrowRight") {
        event.preventDefault();
        draggedTable.position.y += step;
      }
      this.drawTables();
    },
    isInside(pos, rect) {
      const { x, y } = this.relative(rect.position);

      const size = rect.group.options.size / 2;
      const relativeSize = this.relative({ x: size / 100, y: size / 100 });
      const { x: offsetX, y: offsetY } = this.relative(
        {
          x: this.editorProps.offsetX,
          y: this.editorProps.offsetY,
        },
        true
      );

      const isInsideX =
        pos.x >= x - relativeSize.x - offsetX &&
        pos.x <= x + relativeSize.x - offsetX;
      const isInsideY =
        pos.y >= y - relativeSize.y - offsetY &&
        pos.y <= y + relativeSize.y - offsetY;
      return isInsideX && isInsideY;
    },
    save() {
      this.$emit("save", this.partyMap);
    },
    hint() {
      const random = Math.floor(Math.random() * this.hints.length);
      this.selectedHint = random;
      setTimeout(this.hint, 30000);
    },
  },
  computed: {
    tables() {
      return this.partyMap.Groups.flatMap((group) =>
        group.Tables.map((t) => {
          t.group = group;

          return new Proxy(t, {
            set: (target, key, value) => {
              console.log(key, value);
              if (key === "name") {
                target.name = value;
                this.drawTables();
              }
              if (key === "sellOnline") target.sellOnline = value;

              return true;
            },
          });
        })
      );
    },
  },
  watch: {
    tableMap: {
      handler() {
        this.partyMap = Object.assign(this.partyMap, this.tableMap || {});
        this.draw();
      },
      deep: true,
    },
    "partyMap.backgroundUrl": {
      handler() {
        this.setBackground();
      },
    },
    tables: {
      handler() {
        this.drawTables();
      },
      deep: true,
    },
    editorProps: {
      handler() {
        this.draw();
      },
      deep: true,
    },
  },
  mounted() {
    this.partyMap = Object.assign(this.partyMap, this.tableMap || {});
    this.init();
    window.addEventListener("resize", this.calculateCanvasSize);
    window.addEventListener("keydown", this.onKeyDown);
    this.hint();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.calculateCanvasSize);
    window.removeEventListener("keydown", this.onKeyDown);
  },
};
</script>

<style lang="scss">
.leaflet-interactive:focus {
  outline: none;
}
.leaflet-control-container {
  div {
    z-index: 3 !important;
  }
}
.leaflet-pane {
  z-index: 3 !important;
}
.leaflet-control {
  z-index: 4 !important;
}
</style>
