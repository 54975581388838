<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-hover v-slot="{ hover }">
        <v-img :src="basic.cover" class="rounded" :aspect-ratio="16 / 9">
          <v-fade-transition leave-absolute>
            <v-card
              v-if="hover || !basic.cover || fileLoading"
              @click="changeCover"
              class="d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal text-h2 white--text justify-center align-center"
              style="height: 100%; opacity: 0.8"
            >
              <v-progress-circular indeterminate v-if="fileLoading" />
              <v-icon class="white--text" v-else>mdi-camera</v-icon>
            </v-card>
          </v-fade-transition>
        </v-img>
      </v-hover>
      <input
        type="file"
        accept="image/*"
        ref="coverInput"
        @change="onFileChange"
        v-show="false"
      />

      <v-card outlined class="pa-4 mt-4">
        <div class="d-flex align-center flex-wrap">
          <v-responsive :aspect-ratio="16 / 9">
            <iframe
              v-if="basic.youtubeId"
              width="100%"
              height="auto"
              style="border-radius: 8px; border: 0px"
              :src="`https://www.youtube.com/embed/${basic.youtubeId}`"
            >
            </iframe>
            <div
              v-else
              class="d-flex flex-column justify-center align-center w-full h-full"
            >
              <v-icon left x-large>mdi-youtube</v-icon>

              <p>Adicione um video para ver a prévia</p>
            </div>
          </v-responsive>
          <div>
            <h6 class="">Video do Evento</h6>
            <v-text-field
              v-model="basic.youtubeId"
              label="Link do Youtube"
              dense
              outlined
              hide-details
              prefix="youtube.com/watch?v="
            />
          </div>
        </div>
      </v-card>
    </v-col>
    <v-col cols="12" md="8">
      <v-text-field
        v-model="basic.name"
        :counter="30"
        :rules="rules.name"
        label="Nome do evento"
        required
        outlined
      ></v-text-field>

      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="basic.date"
            label="Data"
            outlined
            type="datetime-local"
            required
            hide-details
          />
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            v-model="basic.endDate"
            label="Data final"
            outlined
            type="datetime-local"
            required
            hide-details
            :rules="rules.endDate"
          />
        </v-col>
      </v-row>

      <base-editor label="Descrição" v-model="basic.description" />
    </v-col>
  </v-row>
</template>

<script>
import PARTY from "@/services/admin/party";
import moment from "moment";

export default {
  data() {
    return {
      basic: {
        youtubeId: this.party?.youtubeId,
        cover: this.party?.cover,
        name: this.party?.name,
        description: this.party?.description,
        date: this.party?.date ? this.dateToString(this.party.date) : undefined,
        endDate: this.party?.endDate
          ? this.dateToString(this.party.endDate)
          : undefined,
      },
      fileLoading: false,
      rules: {
        name: [
          (v) => !!v || "Name é obrigatório",
          (v) =>
            (v && v.length <= 30) || "Name deve ter menos de 30 caracteres",
        ],
        endDate: [
          (v) => !!v || "Data final é obrigatório",
          (v) => {
            if (!v) return true;
            const date = new Date(v);
            const start = new Date(this.basic.date);
            return date <= start
              ? "Data final deve ser maior que a data inicial"
              : true;
          },
        ],
      },
    };
  },
  methods: {
    dateToString(date) {
      return moment(date).format("YYYY-MM-DDTHH:mm");
    },
    save() {
      return PARTY.update({
        id: this.party.id,
        organizationId: this.party.organizationId,

        name: this.basic.name,
        description: this.basic.description,
        youtubeId: this.basic.youtubeId ? this.basic.youtubeId : undefined,
        date: this.basic.date ? new Date(this.basic.date) : undefined,
        endDate: this.basic.endDate ? new Date(this.basic.endDate) : undefined,
      });
    },
    changeCover() {
      if (this.fileLoading) return;
      this.$refs.coverInput.click();
    },
    async onFileChange(e) {
      try {
        this.fileError = false;
        this.fileLoading = true;

        const file = e.target.files[0];
        let response = await PARTY.cover(
          this.party.organizationId,
          this.party.id,
          file
        );
        this.partyBkp.cover = response.party.cover;
      } catch (error) {
        this.fileError = error.message;
      } finally {
        this.fileLoading = false;
      }
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
  },
  watch: {
    party: {
      handler: function (val, oldVal) {
        if (!val) return;
        this.basic = {
          name: val.name,
          description: val.description,
          cover: val.cover,
          date: val.date ? this.dateToString(val.date) : undefined,
          endDate: val.endDate ? this.dateToString(val.endDate) : undefined,
        };
      },
      deep: true,
    },
    "basic.name": {
      handler(val) {
        if (val) this.basic.name = this.formatName(val);
      },
      immediate: true,
    },
    "basic.youtubeId": {
      handler(value) {
        if (!value) return;
        const regex =
          /(?:https?:\/\/)?(?:(?:(?:www\.?)?youtube\.com(?:\/(?:(?:watch\?.*?(v=[^&\s]+).*)|(?:v(\/.*))))?)|(?:youtu\.be(\/.*)?))/;
        const match = value.match(regex);
        if (match) {
          const id = match[1] || match[2];
          this.basic.youtubeId = id.replace("v=", "");
        }
      },
      immediate: true,
    },
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
