<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-space-between align-center">
      <h6 class="mb-0">Calendário de vendas</h6>
      <v-btn @click="type = type == 'day' ? 'month' : 'day'" icon small>
        <v-icon small>{{
          type == "day" ? "mdi-calendar-month" : "mdi-calendar"
        }}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
      <template v-else>
        <div class="d-flex align-center">
          <v-btn icon class="ma-2" @click="$refs.calendar.prev()">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            text
            small
            class="ma-2"
            @click="focusToday"
            :disabled="todayIsVisible"
          >
            Hoje
          </v-btn>
          <v-spacer />
          <v-btn icon class="ma-2" @click="$refs.calendar.next()">
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </div>
        <v-calendar
          :event-height="40"
          style="max-height: 450px"
          ref="calendar"
          v-model="value"
          :weekdays="weekday"
          :type="type"
          :events="events"
          :event-overlap-mode="mode"
          :event-overlap-threshold="30"
          :event-color="getEventColor"
          @change="changeCalendar"
          @click:date="viewDay"
        >
          <template v-slot:event="{ event }">
            <div class="ml-1">
              {{ event.total | currency }} <br />
              {{ event.quantity }} ingressos
            </div>
          </template>
        </v-calendar>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  data: () => ({
    error: null,
    focus: null,
    start: null,
    end: null,
    type: "month",
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    value: "",
  }),
  methods: {
    focusToday() {
      this.value = moment().format("YYYY-MM-DD");
    },
    changeCalendar({ start, end }) {
      this.start = start;
      this.end = end;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = this.type == "day" ? "month" : "day";
    },
    getEventColor(event) {
      return event.color;
    },
    filterPayments({ start, end, dateFormat = "YYYY-MM-DD" }) {
      const min = new Date(`${start.date}T00:00:00`);
      const max = new Date(`${end.date}T23:59:59`);
      return this.data.payments
        .filter((p) => {
          const date = new Date(p.payedAt || p.createdAt);
          return date >= min && date <= max && p.status === "succeeded";
        })
        .reduce((acc, p) => {
          const date = new Date(p.payedAt || p.createdAt);
          const key = moment(date).format(dateFormat);
          if (!acc[key]) acc[key] = [];
          acc[key].push(p);
          return acc;
        }, {});
    },
    reducePaymentsToEvents({
      payments,
      groupBy = "day",
      dateFormat = "YYYY-MM-DD",
    }) {
      var events = [];
      for (const [key, p] of Object.entries(payments)) {
        let [quantity, total] = p.reduce(
          (acc, p) => {
            acc[0] += p.Ticket.length;
            acc[1] += p.amount;
            return acc;
          },
          [0, 0]
        );
        events.push({
          start: moment(key, dateFormat).startOf(groupBy).toDate(),
          end: moment(key, dateFormat).endOf(groupBy).toDate(),
          color: "success",
          timed: groupBy === "hour",
          payments: p,
          quantity,
          total,
        });
      }
      return events;
    },
  },
  computed: {
    events() {
      if (!this.start || !this.end) return [];
      const config = {
        format: this.type == "month" ? "YYYY-MM-DD" : "YYYY-MM-DD-HH",
        groupBy: this.type == "month" ? "day" : "hour",
      };

      const payments = this.filterPayments({
        start: this.start,
        end: this.end,
        dateFormat: config.format,
      });
      const events = this.reducePaymentsToEvents({
        payments,
        dateFormat: config.format,
        groupBy: config.groupBy,
      });
      return events;
    },
    todayIsVisible() {
      if (!this.value) return true;
      return moment(this.value).isSame(moment(), this.type);
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
