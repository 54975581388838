<template>
  <div class="mt-2">
    <v-alert v-if="error" type="error">
      <v-row align="center">
        <v-col class="grow py-0">
          {{ error }}
        </v-col>
        <v-col class="shrink py-0">
          <v-btn small @click="getGuests" :disabled="refreshDisabled">
            Tentar novamente
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <template v-if="loading && !itens.length">
      <v-progress-linear indeterminate class="rounded rounded-b-0" />
      <v-alert text type="info" class="mb-0 rounded-t-0">
        Carregando convidados...
      </v-alert>
    </template>
    <v-alert
      text
      v-else-if="!itens.length && !error && !loading"
      type="info"
      class="mb-0"
    >
      Nenhum convidado encontrado
    </v-alert>
    <v-card-text class="px-0" v-else-if="!error">
      <v-data-table
        :headers="headers"
        :items="itens"
        :loading="loading"
        :search="search"
        :custom-filter="filterList"
        dense
      >
        <template v-slot:top>
          <div class="d-flex pa-2">
            <v-text-field
              outlined
              dense
              v-model="search"
              append-icon="mdi-magnify"
              label="Pesquisar (Nome, Email, Telefone)"
              single-line
              hide-details
            />
            <v-btn
              icon
              @click="getGuests"
              :loading="loading"
              :disabled="refreshDisabled"
              class="ml-2"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </template>
        <template v-slot:item.name="{ item }">
          <v-avatar v-if="!item.name" :size="24" class="mr-2">
            <v-icon>mdi-account-question</v-icon>
          </v-avatar>
          <base-avatar
            v-else
            :src="item.photo"
            :seed="item.id"
            :size="24"
            class="mr-2"
          />
          {{ item.name || "Não cadastrado" }}
        </template>
        <template v-slot:item.phone="{ item }">
          <a
            :href="`http://wa.me/55${item.phone.replace(
              /[^0-9]/g,
              ''
            )}?text=Olá, ${item.name}!`"
            target="_blank"
            class="text-decoration-none text--black"
          >
            {{ item.phone }}
          </a>
        </template>

        <template v-slot:item.email="{ item }">
          <a :href="'mailto:' + item.email">{{ item.email }}</a>
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            label
            small
            :color="item.status.color"
            class="font-weight-medium"
          >
            {{ item.status.text }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="viewGuest(item)" :disabled="loading" class="mr-2">
            <v-icon small>mdi-eye</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
    <guest-profile @refresh="getGuests" :party="party" />
  </div>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import GuestProfile from "../modals/Guest/GuestProfile.vue";
const PARTYREPORTS = ORGANIZATION.party.reports;
import PAYMENT from "@/utils/payment";

export default {
  components: { GuestProfile },
  data: () => ({
    loading: false,
    error: false,
    refreshDisabled: false,
    search: "",
    guests: [],
    interval: null,
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "name",
      },
      {
        text: "Whatsapp",
        value: "phone",
        sortable: false,
      },
      {
        text: "Status",
        value: "status",
        sortable: false,
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
      },
    ],
    paymentStatus: PAYMENT.ticketStatus,
  }),
  methods: {
    async getGuests() {
      this.error = false;
      this.loading = true;
      this.refreshDisabled = true;
      try {
        const { guests } = await PARTYREPORTS.guests(
          this.party.organizationId,
          this.party.id
        );
        this.guests = guests;
        setTimeout(() => {
          this.refreshDisabled = false;
        }, 10000);
      } catch (error) {
        setTimeout(() => {
          this.refreshDisabled = false;
        }, 5000);
        this.error = error.message;
      } finally {
        this.loading = false;
      }
    },
    filterName(search, member) {
      if (!member.name) return false;
      const name = member.name.toLowerCase();
      const names = search.trim().toLowerCase().split(" ");
      return names.every((n) => name.includes(n));
    },
    filterPhone(search, member) {
      let p = member.phone.replace(/\D/g, "");
      let s = search.replace(/\D/g, "");
      if (!p || !s) return false;
      return p.includes(s);
    },
    filterEmail(search, member) {
      if (!member.email) return false;
      return member.email.toLowerCase().includes(search.toLowerCase());
    },

    filterList(value, search, item) {
      return (
        this.filterName(search, item) ||
        this.filterPhone(search, item) ||
        this.filterEmail(search, item)
      );
    },
    calculateStatus(guest) {
      if (guest.TicketOwner.some((t) => t.Payment.status == "succeeded"))
        return this.paymentStatus["succeeded"];
      if (
        guest.TicketOwner.some(
          (t) =>
            !["canceled", "refunded", "succeeded"].includes(t.Payment.status)
        )
      )
        return { color: "orange", text: "Aguardando" };
      return { color: "secondary", text: "Não pago" };
    },
    viewGuest(guest) {
      this.$emit("guest-profile", guest);
    },
  },
  computed: {
    itens() {
      return this.guests.map((g) => {
        return {
          ...g,
          status: this.calculateStatus(g),
        };
      });
    },
  },
  mounted() {
    this.getGuests();
    this.interval = setInterval(() => {
      this.getGuests();
    }, 2 * 60 * 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
