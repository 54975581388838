<template>
  <v-dialog v-model="dialog" width="900" scrollable>
    <v-card v-if="whatsappGroup">
      <v-card-title class="d-flex justify-space-between">
        {{ whatsappGroup.name }}
        <v-chip
          class="ml-2"
          label
          small
          dark
          :color="types[whatsappGroup.type].color"
        >
          <v-icon small left>{{ types[whatsappGroup.type].icon }}</v-icon>
          {{ types[whatsappGroup.type].label }}
        </v-chip>
      </v-card-title>
      <v-card-text class="pt-1">
        <v-alert color="grey" text dark>
          <div class="d-flex justify-space-between">
            <span v-if="whatsappGroup.description">
              <b>Descrição</b><br />
              {{ whatsappGroup.description }}
            </span>
            <span v-else> Sem descrição </span>
            <span class="text-h3">
              <v-icon large>mdi-account-multiple</v-icon>
              {{ whatsappGroup.WhatsAppGroupMember.length }}
            </span>
          </div>
        </v-alert>

        <v-alert v-if="!atleticmeInGroup" type="error" text>
          <b>Atenção</b><br />
          A contato da Atletic.Me não está no grupo do WhatsApp
        </v-alert>
        <v-alert
          v-if="atleticmeInGroup && atleticmeInGroup.isAdmin"
          type="warning"
          text
        >
          <b>Atenção</b><br />
          O contato da Atletic.Me <b>não é administrador</b> do grupo do WhatsApp,
          por isso não poderá adicionar novos membros ao grupo.
        </v-alert>

        <div class="d-flex justify-space-between">
          <h6 class="mb-0">Opções</h6>
          <v-btn @click="save" :loading="loading" small color="success">
            Salvar
          </v-btn>
        </div>
        <component
          :is="whatsappGroup.type"
          v-model="whatsappGroup.options"
          :disabled="loading"
        />

        <v-alert v-if="error" type="error" text>
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="close"> Fechar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const { VUE_APP_TICKETME_WHATSAPP } = process.env;

import { mapGetters } from "vuex";
import WHATSAPPGROUP from "@/services/admin/party/whatsappGroup";
import WhatsappGroupTypes from "@/definitions/WhatsappGroupTypes";

import AdminOptions from "@/components/admin/party/whatsappGroup/options/AdminOptions.vue";
import GuestsOptions from "@/components/admin/party/whatsappGroup/options/GuestsOptions.vue";
import EmployeeOptions from "@/components/admin/party/whatsappGroup/options/EmployeeOptions.vue";

export default {
  components: {
    ADMIN: AdminOptions,
    GUESTS: GuestsOptions,
    EMPLOYEE: EmployeeOptions,
  },
  data: () => ({
    dialog: false,
    loading: false,
    whatsappGroup: null,
    types: WhatsappGroupTypes,
  }),

  methods: {
    open(whatsappGroup) {
      this.error = false;
      this.whatsappGroup = Object.assign({}, whatsappGroup);
      this.dialog = true;
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;

        await WHATSAPPGROUP.update(
          this.selectedOrganization.id,
          this.party.id,
          this.whatsappGroup.id,
          {
            options: this.whatsappGroup.options,
          }
        );
        this.$emit("update");
        this.close();
      } catch (error) {
        this.error = error.message || error;
      } finally {
        this.loading = false;
      }
    },
    close() {
      this.dialog = false;
      this.whatsappGroup = null;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    componentName() {
      if (this.whatsappGroup) {
        return this.whatsappGroup.type === "GUESTS"
          ? "GuestOptions"
          : "AdminOptions";
      }
      return null;
    },
    atleticmeInGroup() {
      return this.whatsappGroup.WhatsAppGroupMember.find(
        (member) => member.phone === VUE_APP_TICKETME_WHATSAPP
      );
    },
  },
  mounted() {
    this.$parent.$on("whatsapp-group-view", this.open);
  },
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
