<template>
  <v-dialog
    v-model="dialog"
    width="600"
    scrollable
    :persistent="loading"
    @click:outside="close"
    eager
  >
    <v-card>
      <v-card-title>Perfil do vendedor</v-card-title>
      <v-card-text class="pt-1" v-if="seller">
        <v-card outlined class="pa-2 rounded-xl d-flex gap-3 mb-4 align-center">
          <baseAvatar v-if="!seller.photo" :size="50" :seed="seller.id" />
          <v-avatar v-else size="50">
            <img v-if="seller.name" :src="seller.photo" />
          </v-avatar>
          <div>
            <p class="text-overline mb-0 lh-1" v-if="profile.description">
              {{ (profile.description || "").slice(0, 20) }}
            </p>
            <b v-if="profile.aliasName">
              {{ profile.aliasName }}
            </b>
            <b v-else>
              {{
                (seller.name && $options.filters.firstName(seller.name)) ||
                "Conta não criada"
              }}
            </b>
            <!-- <p class="mb-0">{{ seller.phone }}</p> -->
          </div>
          <v-spacer />
          <div
            v-if="seller.permissions"
            class="d-flex flex-column align-end justify-center gap-1"
          >
            <v-btn icon>
              <v-icon small>mdi-whatsapp</v-icon>
            </v-btn>
          </div>
        </v-card>

        <v-alert :color="hasProfile ? 'success' : 'info'">
          <div class="d-flex justify-space-between white--text">
            <span> Exibir perfil do vendedor na página do evento </span>
            <v-switch
              v-model="hasProfile"
              :disabled="loading"
              :true-value="true"
              :false-value="false"
              hide-details
              color="white"
              class="mt-0 pt-0"
            />
          </div>
        </v-alert>

        <div v-if="hasProfile">
          <v-text-field
            v-model="profile.description"
            :disabled="loading"
            label="Descrição (opcional)"
            outlined
            :rules="[(v) => !v || v.length < 20 || 'Máximo de 20 caracteres']"
            counter="20"
            dense
            hint="Região, faculdade, etc."
            class="mt-2"
          />
          <v-text-field
            v-model="profile.aliasName"
            :disabled="loading"
            label="Nome de exibição (opcional)"
            :placeholder="$options.filters.firstName(seller.name)"
            hint="Substitui o nome do vendedor na página de vendas"
            :rules="[(v) => !v || v.length < 25 || 'Máximo de 25 caracteres']"
            counter="25"
            dense
            outlined
            class="mt-2"
          ></v-text-field>
        </div>

        <v-alert type="error" v-if="error" class="mt-2">
          {{ error }}
        </v-alert>
      </v-card-text>

      <v-card-actions>
        <v-btn class="ml-1" text :disabled="loading" @click="close">
          Fechar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn class="ml-1" color="primary" :loading="loading" @click="save">
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SELLER from "@/services/admin/party/seller";

export default {
  data: () => ({
    dialog: false,
    error: false,
    valid: false,
    loading: false,
    ticketGroups: false,
    seller: null,
    profile: {
      description: "",
      aliasName: "",
    },
    hasProfile: false,
    hasProfileOriginal: false,
  }),

  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;

        const op = this.hasProfile ? "update" : "delete";

        await SELLER.profile[op](
          this.party.organizationId,
          this.party.id,
          this.seller.id,
          {
            aliasName: this.profile.aliasName,
            description: this.profile.description,
          }
        );

        this.$emit("success");
        this.loading = false;
        this.close();
      } catch (e) {
        this.loading = false;
        this.error = e.message || e;
      }
    },
    open(seller = {}) {
      this.seller = Object.assign({}, seller);

      this.profile = Object.assign(
        {
          description: "",
          aliasName: "",
        },
        this.seller.SellerProfile
      );

      this.hasProfile = !!seller.SellerProfile;
      this.hasProfileOriginal = !!seller.SellerProfile;

      this.error = false;
      this.dialog = true;
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.seller = null;
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
  },
  mounted() {
    this.$root.$on("seller-profile-modal", this.open);
  },
  created() {
    this.getTicketGroups();
  },
  watch: {},
  props: {
    party: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
