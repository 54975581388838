<template>
  <div>
    <v-data-table
      :items="paymentsByType"
      :headers="headers"
      hide-default-footer
      class="my-2"
      :custom-sort="sortFunc"
    >
      <template v-slot:item.paymentType="{ item }">
        <component :is="item.total ? 'b' : 'span'">
          {{ item.paymentType }}
        </component>
      </template>
      <template v-slot:item.amount="{ item }">
        <component :is="item.total ? 'b' : 'span'">
          {{ item.amount | currency }}
        </component>
      </template>
      <template v-slot:item.integrationFee="{ item }">
        <component :is="item.total ? 'b' : 'span'">
          {{ item.integrationFee | currency }} ({{
            ((-item.integrationFee / item.amount) * 100) | percent(2)
          }})
        </component>
      </template>
      <template v-slot:item.platformFee="{ item }">
        <component :is="item.total ? 'b' : 'span'">
          {{ item.platformFee | currency }} ({{
            ((-item.platformFee / item.amount) * 100) | percent
          }})
        </component>
      </template>
      <template v-slot:item.netAmount="{ item }">
        <b class="font-weight-bold">
          {{ item.netAmount | currency }} ({{
            ((item.netAmount / item.amount) * 100) | percent
          }})
        </b>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
export default {
  data: () => ({
    headers: [
      {
        text: "Forma de pagamento",
        value: "paymentType",
        sortable: true,
      },
      {
        text: "Valor Bruto",
        value: "amount",
        sortable: true,
      },
      {
        text: "Taxa Mercado Pago",
        value: "integrationFee",
        sortable: true,
      },
      {
        text: "Taxa Plataforma",
        value: "platformFee",
        sortable: true,
      },
      {
        text: "Valor Líquido",
        value: "netAmount",
        sortable: true,
      },
    ],
    paymentTypes: {
      UNKNOWN: "Desconhecido",
      CREDIT_CARD: "Cartão de Crédito",
      DEBIT_CARD: "Cartão de Débito",
      PIX: "Pix",
      PAYPAL: "Paypal",
      BANK_TRANSFER: "Transferência Bancária",
      COURTESY: "Cortesia",
      MONEY: "Dinheiro",
    },
  }),

  methods: {
    sortFunc(items, [sortBy], [sortDesc]) {
      if (!sortBy) return items;
      return items.sort((a, b) => {
        if (a.total) return 1;
        if (b.total) return -1;
        const valueA = a[sortBy];
        const valueB = b[sortBy];
        if (typeof valueA === "number" && typeof valueB === "number") {
          return sortDesc ? valueB - valueA : valueA - valueB;
        }
        return sortDesc
          ? valueB.localeCompare(valueA)
          : valueA.localeCompare(valueB);
      });
    },
  },

  computed: {
    paymentsByType() {
      const total = {
        amount: 0,
        integrationFee: 0,
        platformFee: 0,
        netAmount: 0,
      };

      return [
        ...Object.entries(
          this.object.payments.reduce((acc, p) => {
            const paymentType = p.paymentType;
            if (!acc[paymentType])
              acc[paymentType] = {
                amount: 0,
                integrationFee: 0,
                platformFee: 0,
                netAmount: 0,
              };
            acc[paymentType].amount += p.amount;
            acc[paymentType].integrationFee += p.integrationFee;
            acc[paymentType].platformFee += p.platformFee;
            acc[paymentType].netAmount +=
              p.amount - p.integrationFee - p.platformFee;

            total.amount += p.amount;
            total.integrationFee += p.integrationFee;
            total.platformFee += p.platformFee;
            total.netAmount += p.amount - p.integrationFee - p.platformFee;

            return acc;
          }, {})
        ).map(([paymentType, payment]) => {
          return {
            paymentType: this.paymentTypes[paymentType],
            amount: payment.amount,
            integrationFee: payment.integrationFee,
            platformFee: payment.platformFee,
            netAmount: payment.netAmount,
          };
        }),
        {
          total: true,
          paymentType: "Total",
          amount: total.amount,
          integrationFee: total.integrationFee,
          platformFee: total.platformFee,
          netAmount: total.netAmount,
        },
      ];
    },
  },
  filters: {
    date(value) {
      return moment(value).format("DD/MM/YYYY HH:mm");
    },
    percent(value, fixed = 0) {
      return value.toFixed(fixed) + "%";
    },
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style></style>
