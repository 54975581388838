<template>
  <v-dialog v-model="isOpen" width="500">
    <v-card>
      <v-card-title class="text-h6">
        {{
          finished
            ? "Setor Criado"
            : ticketGroup.id
            ? "Editar setor"
            : "Adicionar setor"
        }}
      </v-card-title>
      <template v-if="!finished">
        <v-tabs v-if="ticketGroup.id && tabs.length > 1" grow v-model="tab">
          <v-tab v-for="tab in tabs" :key="tab.value" :tab-value="tab.value">
            {{ tab.name }}
            <v-chip
              v-if="
                tab.value === 'restrictions' &&
                restrictions.whatsappGroups.length
              "
              small
              class="px-2 ml-2"
            >
              1
            </v-chip>
          </v-tab>
        </v-tabs>
        <v-card-text class="pa-4 pt-2">
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                v-model="valid"
                ref="form"
                @submit.prevent="save"
                class="pt-2"
              >
                <v-text-field
                  v-model="ticketGroup.name"
                  :rules="[(v) => !!v || 'Nome é obrigatório']"
                  label="Nome"
                  dense
                  required
                  outlined
                ></v-text-field>
                <v-textarea
                  v-model="ticketGroup.description"
                  label="Descrição"
                  rows="2"
                  auto-grow
                  outlined
                ></v-textarea>

                <v-select
                  v-if="mapGroups.length"
                  v-model="ticketGroup.tableGroupId"
                  :items="mapGroups"
                  item-text="name"
                  item-value="id"
                  label="Grupo de Mesas"
                  dense
                  outlined
                ></v-select>
              </v-form>
            </v-tab-item>
            <v-tab-item value="restrictions">
              <v-alert type="info" text dense>
                <b>Restrições de Acesso</b> <br />
                Defina restrições para controlar quem pode adquirir ingressos
                <b>online</b> para este setor.
              </v-alert>

              <v-card outlined class="py-2">
                <h6 class="px-4">Ser membro de um dos grupos selecionados</h6>
                <v-list dense class="py-0">
                  <v-list-item v-for="group in whatsappGroups" :key="group.id">
                    <v-list-item-content>
                      <div class="d-flex align-center gap-3">
                        <v-avatar size="32">
                          <v-icon v-if="!group.image">
                            mdi-account-multiple
                          </v-icon>
                          <v-img v-else :src="group.image" />
                        </v-avatar>
                        <p class="mb-0 flex-grow-1 font-weight-bold">
                          {{ group.name }}
                        </p>

                        <v-chip small>
                          <v-icon small left>mdi-account-multiple</v-icon>
                          {{ group.WhatsAppGroupMember.length }}
                        </v-chip>
                        <v-checkbox
                          v-model="restrictions.whatsappGroups"
                          :value="group.id"
                          class="mt-0 pt-0"
                          hide-details
                        />
                      </div>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <v-alert v-if="!!error" outlined text color="error">
            {{ error }}
          </v-alert>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="loading" @click="close"> Cancelar </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            :disabled="loading || !valid"
            :loading="loading"
            @click="save"
          >
            Confirmar
          </v-btn>
        </v-card-actions>
      </template>

      <!-- Success -->
      <template v-else>
        <v-card-text>
          <v-alert type="success" outlined text>
            <b>Categoria criada com sucesso!</b> <br />
            Para ela ficar ativa, é preciso criar um lote. <br />
            Deseja criar um agora?
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text :disabled="loading" @click="close">
            finalizar depois
          </v-btn>
          <v-btn
            class="ml-2"
            color="primary"
            :disabled="loading"
            :loading="loading"
            @click="addTicketBlock"
          >
            Criar lote
          </v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import TICKET from "@/services/admin/ticket";
import WHATSAPPGROUP from "@/services/admin/party/whatsappGroup";

const defaultTicketGroup = () => ({
  id: null,
  name: "",
  description: "",
  tableGroupId: null,
});
const defaultRestrictions = () => ({
  whatsappGroups: [],
});

export default {
  props: {
    tableMap: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    isOpen: false,
    loading: false,
    valid: false,
    error: false,
    finished: false,
    ticketGroup: defaultTicketGroup(),
    restrictions: defaultRestrictions(),
    whatsappGroups: [],
    tab: "details",
  }),

  methods: {
    open(data = {}) {
      this.finished = false;
      this.tab = "details";
      this.isOpen = true;
      this.loadData(data);
      this.$nextTick(() => {
        this.$refs.form.resetValidation();
      });
    },
    loadData(data) {
      const { WhatsappGroups, ...ticketGroup } = data;
      this.ticketGroup = Object.assign(defaultTicketGroup(), ticketGroup);
      this.restrictions = defaultRestrictions();
      if (WhatsappGroups.length)
        this.restrictions.whatsappGroups = WhatsappGroups.map((g) => g.id);
    },
    close() {
      this.finished = false;
      this.tab = "details";
      this.isOpen = false;
      this.loading = false;
      this.error = false;
      this.ticketGroup = defaultTicketGroup();
      this.restrictions = defaultRestrictions();
    },
    addTicketBlock() {
      if (!this.ticketGroup.id) return;
      this.$root.$emit("ticket-block-modal", {
        ticketGroup: this.ticketGroup.id,
      });
      this.close();
    },
    async save() {
      try {
        this.loading = true;
        this.error = false;
        let orgId = this.selectedOrganization.id;
        let { partyId } = this.$route.params;
        if (this.ticketGroup.id) {
          await TICKET.updateTicketGroup(orgId, partyId, {
            id: this.ticketGroup.id,
            name: this.ticketGroup.name,
            description: this.ticketGroup.description,
            tableGroupId: this.ticketGroup.tableGroupId,
            restrictions: this.restrictions,
          });
          this.close();
        } else {
          var { ticketGroup } = await TICKET.createTicketGroup(orgId, partyId, {
            name: this.ticketGroup.name,
            description: this.ticketGroup.description,
            restrictions: this.restrictions,
          });
          this.finished = true;
          this.ticketGroup.id = ticketGroup.id;
        }
        this.$emit("success");
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    formatName(name) {
      return name
        .split(" ")
        .map((n) => (n.length > 3 ? n.charAt(0).toUpperCase() + n.slice(1) : n))
        .join(" ");
    },
    async loadWhatsappGroups() {
      try {
        let orgId = this.selectedOrganization.id;
        let { partyId } = this.$route.params;
        const { whatsappGroup } = await WHATSAPPGROUP.getAll(orgId, partyId);
        this.whatsappGroups = whatsappGroup;
      } catch (e) {
        console.log(e);
      }
    },
  },
  watch: {
    "ticketGroup.name": {
      handler: function (val) {
        this.ticketGroup.name = this.formatName(val);
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    mapGroups() {
      if (!this.tableMap) return [];
      return this.tableMap.Groups.map((group) => ({
        ...group,
        Tables: group.Tables.reduce(
          (acc, table) => {
            acc.total += 1;
            if (!table.Ticket) acc.available += 1;
            return acc;
          },
          { total: 0, available: 0 }
        ),
      }));
    },

    tabs() {
      if (this.whatsappGroups.length)
        return [
          {
            name: "Detalhes",
            value: "details",
          },
          {
            name: "Restrições",
            value: "restrictions",
          },
        ];
      return [
        {
          name: "Detalhes",
          value: "details",
        },
      ];
    },
  },
  mounted() {
    this.$root.$on("ticket-group-modal", this.open);
    this.loadWhatsappGroups();
  },
};
</script>

<style></style>
