<template>
  <v-card outlined :loading="loading">
    <v-card-title class="justify-end">
      <h6>
        Vendas por período
        <span class="text-subtitle-2 text--secondary">Aprovadas</span>
      </h6>
      <v-spacer />
      <v-btn-toggle mandatory v-model="displayGroup">
        <v-btn small> Dia </v-btn>
        <v-btn small> Dia da semana </v-btn>
        <v-btn small> Hora </v-btn>
      </v-btn-toggle>
      <v-btn-toggle mandatory v-model="display" class="ml-2">
        <v-btn small> R$ </v-btn>
        <v-btn small> Qtde </v-btn>
      </v-btn-toggle>
    </v-card-title>
    <v-card-text>
      <h6></h6>
      <ApexCharts
        type="line"
        height="350"
        :options="chartOptions"
        :series="chartSeries"
      />

      <v-alert v-if="error" type="error" class="mb-0">
        {{ error }}
      </v-alert>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from "moment";
import ApexCharts from "vue-apexcharts";

export default {
  components: {
    ApexCharts,
  },
  data: () => ({
    error: null,
    display: 0,
    displayGroup: 0,
  }),
  computed: {
    xaxis() {
      const opts = [
        {
          format: "D",
          categories: Array.from({ length: 31 }, (_, i) => i + 1),
          hasZero: false,
        },
        {
          length: 7,
          format: "d",
          categories: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
          hasZero: true,
        },
        {
          length: 24,
          format: "H",
          categories: Array.from({ length: 24 }, (_, i) => i + "h"),
          hasZero: true,
        },
      ];
      return opts[this.displayGroup];
    },
    chartSeries() {
      let { categories, format, hasZero } = this.xaxis;

      const sellsByDay = this.data.payments.reduce((acc, p) => {
        let day = moment(p.payedAt || p.createdAt).format(format);
        if (p.status !== "succeeded") return acc;

        acc[day] += this.display === 0 ? p.amount : 1;
        return acc;
      }, Object.fromEntries(Array.from({ length: categories.length }, (_, i) => [hasZero ? i : i + 1, 0])));

      return [
        {
          name: "Vendas",
          data: Object.values(sellsByDay),
        },
      ];
    },
    chartOptions() {
      let { categories } = this.xaxis;
      return {
        chart: {
          type: "line",
          height: 350,

          toolbar: {
            show: false,
          },
        },
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 1,
        },
        colors: ["#5e5ce6", "#fbaf0f"],
        stroke: {
          width: 7,
          curve: "smooth",
        },

        xaxis: {
          categories,
          title: {
            text: "Dia",
          },
          axisBorder: {
            show: false,
          },
        },
        yaxis: {
          title: {
            text: this.display === 0 ? "R$" : "Qtde",
          },
          labels: {
            formatter: (value) => {
              return this.display === 0
                ? value.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : value.toFixed(0);
            },
          },
        },
        markers: {
          size: 5,
          hover: {
            size: 7,
          },
        },
        grid: {
          borderColor: "transparent",
          row: {
            colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
            opacity: 0,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -25,
          offsetX: -5,
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: (seriesName) =>
              this.display === 0
                ? seriesName.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })
                : seriesName.toFixed(0) + " vendas",
          },
        },
      };
    },
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style></style>
